import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './HeroCarousel.css';

const HeroCarousel = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        fetchImages();
    }, []);

    const fetchImages = async () => {
        try {
            const response = await fetch('https://jsonplaceholder.typicode.com/photos?_limit=5');
            const data = await response.json();
            const screenWidth = window.innerWidth;
            if (screenWidth < 768) {
                data[0].url = "https://img.boutirapp.com/i/Jj5MuCzNQff0IVJqLG5G9D1vcCISfRRR5lMoZgZDrD7";
                data[1].url = "https://img.boutirapp.com/i/QIOo-U7HgKRKMYRffaqbXvOO0d5MNan97MUEr8Y12I-";
                data[2].url = "https://img.boutirapp.com/i/FsGSkweLQiisX5ph-U72o8p3cJHRtFwhCcdhNzFbYPJ";
                data[3].url = "https://img.boutirapp.com/i/7upG2YNbKoZmWV2k7FrHdTd0HwbTF9hkWDiiYpLQoBs054oJgmbb6pfFLi1xmqv3KvDBkfo8I3Wi6N4MTHC9BZ";
                data[4].url = "https://img.boutirapp.com/i/Bfaz-hDN-ptXZzPKcoBOLEsEvcyh7bES0fjt9nX3i5FWkzt2V6aCR5tu0rc7Kr7Lxp3JLj6tQWI-LZ3aACG5Vn"
            } else {
                data[0].url = "https://img.boutir.co/i/0Q-v3BmZylpv1E31EKuKY1WIBYwrjFx5dRFN2yCzjno";
                data[1].url = "https://img.boutir.co/i/2QWR8v3GAd4HxamTJAFVLLSw2lQzWmZf9oNyFz8-EPH";
                data[2].url = "https://img.boutir.co/i/Fbjj282t9d0CXIVgS8MvFplkDTpApb20Xh0M-msWigf";
                data[3].url = "https://img.boutirapp.com/i/24gwPZ2ZwF0x13nCs6iybFSEn9lPTrkfzMKBX7uPiL-PP9ECGR0WFIbeaiEtDHsUk9eTia8q9MkJtr8DMCOhk2";
                data[4].url = "https://img.boutirapp.com/i/A1t-9cABvr4m0ygR4LWJetGL5WkpTPqPRl3vLNxy1gs6gGEXNCaVTR9tIz8j6gmIhaIgXCdhSSB2lQ1dnO-vzu"
            }
            setImages(data);
        } catch (error) {
            console.error('Error fetching carousel images:', error);
        }
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        centerMode: true,
        centerPadding: '0px', // Adjust this value as needed
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '0px', // Adjust this value as needed
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '0px', // Adjust this value as needed
                },
            },
        ],
    };

    return (
        <div className="hero-carousel">
            <Slider {...settings}>
                {images.map((image) => (
                    <img className="carousel-image" src={image.url} alt={image.title} />
                ))}
            </Slider>
        </div>
    );
};

export default HeroCarousel;
