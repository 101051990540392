import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function ShopMenu() {
    const { t, i18n } = useTranslation();

    return (
        <div className='content'>
            <h1>ShopMenu</h1>

        </div>
    );
}

export default ShopMenu;