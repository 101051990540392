import React from 'react';
import './ProductFilter.css';

const ProductFilter = ({ sizes, brands, selectedSizes, closeModal, selectedMinPrice, selectedMaxPrice, handleSizeChange, handleBrandChange, handleMinPriceChange, handleMaxPriceChange, applyFilters, clearFilters }) => {
    return (
        <div className="product-filter-container">
            <button className="close-button" onClick={closeModal}>&times;</button>
            <div className='brand-filter'>
                <h3>Brands:</h3>
                <select onChange={(e) => handleBrandChange(e.target.value)}>
                    <option value="">Select Brand</option>
                    {brands.map((brand) => (
                        <option key={brand} value={brand}>
                            {brand}
                        </option>
                    ))}
                </select>
            </div>
            <div className="size-filter">
                <h3>Size:</h3>
                <div className="size-options">
                    {sizes.map((size) => (
                        <button
                            key={size}
                            className={`size-button ${selectedSizes.includes(size) ? 'active' : ''}`}
                            onClick={() => handleSizeChange(size)}
                        >
                            {size}
                        </button>
                    ))}
                </div>
            </div>
            <div className="filter-buttons">
                <button className="apply-button" onClick={() => applyFilters(selectedSizes)}>
                    Apply Filters
                </button>
                <button className="clear-button" onClick={clearFilters}>
                    Clear Filters
                </button>
            </div>
        </div>
    );
};

export default ProductFilter;
