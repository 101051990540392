import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { getMyOrder } from '../API/api';
import './OrderPage.css';
import { auth } from '../firebase';
import { onAuthStateChanged } from "firebase/auth"; // Import getAuth
function OrderPage() {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate("/account");
    };
    const handleOrderClick = (orderNumber) => {
        navigate(`/order/${orderNumber}`);
    };

    const [myOrderList, setMyOrderList] = useState([]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log("onAuthStateChanged user: ", user);
            fetchMyOrder();
        });
        // Cleanup function to unsubscribe to the listener when the component unmounts
        return () => unsubscribe();
    }, []); // Empty dependency array ensures this effect runs only once on mount


    const fetchMyOrder = async () => {
        try {
            let data = await getMyOrder();
            console.log(data.outputList);
            setMyOrderList(data.outputList);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    }


    return (
        <div className="order-page-container">
            <div className="header">
                <button className="backButton" onClick={handleBackClick}>Back</button>
            </div>
            <h2>Order History</h2>

            <div className="order-list">
                {myOrderList.map(order => (
                    <div className="order-card">
                        <div className="order-info">
                            <div className="order-row"><small>Order Number:</small> <small>{order.orderNumber}</small></div>
                            <div className="order-row"><small>Order Date:</small> <small>{order.orderDate}</small></div>
                            <div className="order-row"><small>Price:</small> <small>{order.price}</small></div>
                            <div className="order-row"><small>Status:</small> <small>{order.status}</small></div>
                            <div className="order-row"><small>Promo Code:</small> <small>{order.promoCode}</small></div>
                            <div className="order-row"><small>Payment Status:</small> <small>{order.payment}</small></div>
                            <div className="order-row"><small>Remark:</small> <small>{order.remark}</small></div>
                            <div className="order-row" onClick={() => handleOrderClick(order.orderNumber)}><small>Order Detail >>>>> </small></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OrderPage;