import React from 'react';
import './BrandButton.css';

const BrandButton = ({ brandName }) => {
    return (
        <button className="brand-button">{brandName}</button>
    );
};

export default BrandButton;
