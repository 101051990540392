import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser, faShoppingCart, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from "react-router-dom";
import './TopNavBar.css';
import { getCurrentUser } from '../firebase';
import { onAuthStateChanged } from "firebase/auth"; // Import getAuth
import { auth } from '../firebase';
import brandsConst from "../constant/brandname";
import { useLocation } from 'react-router-dom';

const TopNavBar = ({ handleMenuClick, cartItemCount, showMenu, toggleMenu, closeMenu }) => {

    const [activeSubmenu, setActiveSubmenu] = useState('');
    const [userDisplayName, setUserDisplayName] = useState('');
    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log("onAuthStateChanged user: ", user);
            if (user) {
                setUserDisplayName(user.displayName);
            } else {
                // User is signed out, set the current user to null
                setUserDisplayName(null);
            }
        });
        // Cleanup function to unsubscribe to the listener when the component unmounts
        return () => {
            unsubscribe();
        }
    }, []); // Empty dependency array ensures this effect runs only once on mount

    const handleMarvooClick = () => {
        console.log('Marvoo');
        closeMenu();
        navigate("/");
    };

    const handleFBClick = () => {
        console.log("FB");
    }

    const handleIGClick = () => {
        console.log("IG");
    }

    const handleCartClick = () => {
        console.log('cart');
        closeMenu();
        navigate("/cart");
    };

    const handleUserLogin = () => {
        if (getCurrentUser()) {
            navigate("/account");
        } else {
            navigate("/signup/");
        }
    }

    const toggleSubmenu = (name) => {
        if (name === "No Fakes Pledge Store") {
            navigate("/noFakeStore");
            toggleMenu();
        } else {
            setActiveSubmenu((prevActiveSubmenu) => (prevActiveSubmenu === name ? '' : name));
        }
    };

    const handleSubmenuItemClicked = (name) => {
        handleMenuClick(name);
        //setShowMenu(false);
        const currentPath = location.pathname;
        const isProductPage = currentPath.includes('/product/');
        // Now you can use the isProductPage variable as needed
        if (isProductPage) {
            console.log('Current page is a product page');
            navigate(-1);
        }
    }
    // useEffect(() => {

    //     const selectedLang = localStorage.getItem('selectedLang');
    //     if (selectedLang) {
    //         handleSubmenuItemClicked(selectedLang);
    //     }
    // }, [i18n]);

    const menuItems = [
        // { label: 'Brand', submenuItems: brandsConst },
        { label: 'Everything else', submenuItems: [] },
        { label: 'Shopping Bag', submenuItems: [] },
        { label: 'Wishlist', submenuItems: [] },
        { label: 'Account', submenuItems: [] },
        { label: 'Customer Care', submenuItems: [] },
        { label: 'Live Assistance', submenuItems: [] },
        { label: 'No Fakes Pledge Store', submenuItems: [] },
        { label: 'Location', submenuItems: [] },
        { label: 'Language', submenuItems: [t('navbar.chinese'), t('navbar.english')] }
    ];

    return (
        <nav className="navbar">
            <div className="navbar-left">
                <FontAwesomeIcon icon={faBars} onClick={toggleMenu} />
            </div>
            {showMenu && (
                <div className="dropdown-menu" >
                    <ul>
                        {menuItems.map((menuItem, index) => (
                            <li key={index} onClick={() => toggleSubmenu(menuItem.label)}>
                                {menuItem.label}
                                {menuItem.submenuItems.length > 0 && (
                                    <FontAwesomeIcon
                                        icon={faAngleRight}
                                        className={`more-icon ${activeSubmenu === menuItem.label ? 'active' : ''}`}
                                    />
                                )}
                                {activeSubmenu === menuItem.label && (
                                    <ul className="submenu">
                                        {menuItem.submenuItems.map((submenuItem, subIndex) => (
                                            <li key={subIndex} onClick={() => handleSubmenuItemClicked(submenuItem)} >{submenuItem}</li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            <div className="navbar-center">
                <h1 className="navbar-title">
                    <FontAwesomeIcon icon={faFacebook} onClick={handleFBClick} />
                    <FontAwesomeIcon icon={faInstagram} onClick={handleIGClick} />
                    <span onClick={handleMarvooClick}>MARVOO</span>
                </h1>
            </div>
            <div className="navbar-right">
                <span>{userDisplayName}</span>
                <FontAwesomeIcon icon={faUser} onClick={handleUserLogin} />
                <div className="cart-container">
                    <FontAwesomeIcon icon={faShoppingCart} onClick={handleCartClick} />
                    {cartItemCount > 0 && <span className="badge">{cartItemCount}</span>}
                </div>
            </div>
        </nav>
    );
};

export default TopNavBar;
