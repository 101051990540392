import React, { useState, useEffect, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useNavigate, useParams } from "react-router-dom";
import './ProductDetailPage.css';
import API_URL from '../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHeart, faShare, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import DropdownComponent from './DropdownComponent';
import PricePopup from './PricePopup';
import { toast } from 'react-toastify';
import { auth, getCurrentUser } from '../firebase';
import { confirmPasswordReset, onAuthStateChanged } from 'firebase/auth';
import FloatingChatChannel from '../Channel/FloatingChatChannel';

const ProductDetailPage = (props) => {
  const navigate = useNavigate();

  const { productId } = useParams();

  const [product, setProduct] = useState();
  const [brand, setBrand] = useState();
  const [isLike, setIsLike] = useState(false);
  const [showPricePopup, setShowPricePopup] = useState(false);
  const [mode, setMode] = useState(0);

  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    const hasItemsWithQty = product.items.some((item) => item.qty > 0);
    if (hasItemsWithQty) { setShowDropdown((prevState) => !prevState); }
    else {
      toast.info("This item SOLD OUT");
    }
  };

  const handleLike = () => {
    addMyList(product, !isLike);
    setIsLike(!isLike);
  }

  const handleShare = () => {
    console.log("handleShare");
  }

  const handleAdditionalImageClick = (index) => {
  };

  const handleShowPrice = (mode) => {
    setMode(mode);
    setShowPricePopup(true);
  }

  const handleClosePricePopup = () => {
    setShowPricePopup(false);
  };

  const handleProductContainerClick = () => {
    if (showDropdown) setShowDropdown(false);
  }

  const handleContact = (product) => {
    console.log(product);
    // Replace '1234567890' with the recipient's phone number
    const phoneNumber = '+85296798860';

    // Replace 'Hello%20world!' with the URL-encoded message
    const message = '我想詢問關於：' + product.name;

    // Construct the WhatsApp link
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;

    // Open WhatsApp in a new tab/window
    window.open(whatsappLink, '_blank');
  }

  // Reference to the carousel component
  var carouselRef = useRef(null);

  useEffect(() => {
    fetchProducts();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (getCurrentUser() != undefined) {
        let obj = await getMyList(getCurrentUser().uid);
        try {
          var list = [];
          for (var i = 0; i < obj.list.length; i++) {
            list.push(obj.list[i].productId);
            if (obj.list[i].productId === productId) {
              setIsLike(true);
            }
          }
        } catch (err) {
          console.error(err);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await fetch(`${API_URL}/spulist?status=1&productId=${productId}`);

      const data = await response.json();
      const product = data.list[0];

      {
        const brandIDResponse = await fetch(`${API_URL}/brandlist?brandId=${product.brandId}`);
        const data = await brandIDResponse.json();
        const brand = data.list[0].englishName;
        console.log(brand);
        setBrand(brand);
      }

      setProduct(product);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  async function getMyList(uid) {
    const myListResponse = await fetch(`${API_URL}/mylist?uid=${uid}`, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const myListResp = await myListResponse.json();
    let myList = myListResp.myList[0];
    return myList;
  }

  async function addMyList(product, isAdded) {
    try {
      let user = getCurrentUser();
      if (user != undefined) {
        const uid = user.uid;
        const brandIDResponse = await fetch(`${API_URL}/brandlist?brandId=${product.brandId}`);
        const data = await brandIDResponse.json();
        const brand = data.list[0].englishName;

        const temp = {
          "uid": uid,
          "list": [
            {
              "productId": product.productId,
              "brandId": product.brandId,
              "brand": brand,
              "thumbnail": product.images[0],
              "description": product.name,
              "size": "",
              "price": product.retailPrice,
              "updatedDate": Date.now()
            }
          ]
        }

        const myListResponse = await fetch(`${API_URL}/mylist?uid=${uid}`, {
          headers: {
            'Content-Type': 'application/json',
          }
        });

        const myListResp = await myListResponse.json();
        let myList = myListResp.myList[0];
        console.log(myList);

        if (myList === undefined) {
          if (!isAdded) { return; }
          const response = await fetch(`${API_URL}/mylist`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(temp),
          });
        } else {
          const myListIndex = myList.list.findIndex((list) => list.productId == product.productId);
          if (myListIndex !== -1) {
            if (!isAdded) {
              myList.list.splice(myListIndex, 1);
            } else {
              return;
            }
          } else {
            if (isAdded) {
              myList.list.push(temp.list[0]);
            }
          }
          const response = await fetch(`${API_URL}/mylist`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(myList),
          });
        }

      }
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }

  if (!product) {
    return <div>Loading...</div>; // or any loading state/component
  }

  return (
    <div>
      <div className="product-detail-container" onClick={handleProductContainerClick}>
        <FloatingChatChannel />
        <div className="header">
          <button className="backButton" onClick={() => navigate(-1)}>Back</button>
          <div className="header-icons">
            <button className="heart-icon-button" onClick={handleLike}>
              <FontAwesomeIcon icon={faHeart} className={`productpage-heart-icon ${isLike ? 'red' : ''}`} />
            </button>
            <button className="share-icon-button" onClick={handleShare}>
              <FontAwesomeIcon icon={faShare} className="icon share-icon" />
            </button>
          </div>
        </div>
        <div className="product-container">
          <Carousel showThumbs={false} ref={carouselRef}>
            {product.images.map((image, index) => (
              <div key={index}>
                <img src={image} alt={`Product ${index}`} className="product-image" />
              </div>
            ))}
          </Carousel>

          <div className="additional-images">
            {product.images.map((image, index) => (
              <div key={index} className="additional-image-wrapper">
                <img
                  src={image}
                  alt={`Product ${index}`}
                  className="additional-image"
                  onClick={() => handleAdditionalImageClick(index)}
                />
              </div>
            ))}
          </div>
          <h2 className='product-name'>{brand}</h2>
          <h2 className='product-name'>{product.name}</h2>

          <div className="price-container">
            <p className='product-price'>HKD $ {product.retailPrice}</p>
            <p className='different-price-statement' onClick={() => handleShowPrice(0)}> <strong>不同碼不同價錢?</strong></p>
          </div>

          <div className="price-container">
            <p onClick={() => handleShowPrice(1)}>購買需知</p>
            <FontAwesomeIcon icon={faArrowDown} className='product-price-statement' onClick={() => handleShowPrice(1)} />
          </div>

          <PricePopup show={showPricePopup} onClose={handleClosePricePopup} mode={mode} />

          {brand !== "Marvoo" ? <DropdownComponent
            items={product.items}
            product={product}
            brand={brand}
            handleAddToCart={props.handleAddToCart}
            showDropdown={showDropdown}
            toggleDropdown={toggleDropdown}
          /> : <button className="add-to-cart-button" onClick={() => handleContact(product)}>直接聯絡我們查詢</button>}

          {brand !== "Marvoo" ?
            <div>
              <p>產品編號:  {product.designerId}</p>
              <p>訂購時間: 預計2-3星期到達香港</p></div> : ''}
          <p>圖片只供參巧，相片有機會有色差，一切以實物為準</p>

        </div>
      </div>
    </div >
  );
};

export default ProductDetailPage;