const API_URL = "http://18.166.69.200:8888";
const { getCurrentUser } = require("../firebase");

async function getMyList(uid) {
    console.log("getMyList: " + uid);
    const myListResponse = await fetch(`${API_URL}/mylist?uid=${uid}`, {
        headers: {
            'Content-Type': 'application/json',
        }
    });

    const myListResp = await myListResponse.json();
    let myList = myListResp.myList[0];
    return myList;
}

async function addMyList(product, isAdded) {
    try {
        let user = getCurrentUser();
        if (user != undefined) {
            const uid = user.uid;
            const brandIDResponse = await fetch(`${API_URL}/brandlist?brandId=${product.brandId}`);
            const data = await brandIDResponse.json();
            const brand = data.list[0].englishName;


            const temp = {
                "uid": uid,
                "list": [
                    {
                        "productId": product.productId,
                        "brandId": product.brandId,
                        "brand": brand,
                        "thumbnail": product.images[0],
                        "description": product.name,
                        "size": "",
                        "price": product.retailPrice,
                        "updatedDate": Date.now()
                    }
                ]
            }

            const myListResponse = await fetch(`${API_URL}/mylist?uid=${uid}`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const myListResp = await myListResponse.json();
            let myList = myListResp.myList[0];
            console.log(myList);

            if (myList === undefined) {
                if (!isAdded) { return; }
                const response = await fetch(`${API_URL}/mylist`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(temp),
                });
            } else {
                const myListIndex = myList.list.findIndex((list) => list.productId == product.productId);
                if (myListIndex !== -1) {
                    if (!isAdded) {
                        myList.list.splice(myListIndex, 1);
                    } else {
                        return;
                    }
                } else {
                    if (isAdded) {
                        myList.list.push(temp.list[0]);
                    }
                }
                const response = await fetch(`${API_URL}/mylist`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(myList),
                });
            }

        }
    } catch (error) {
        console.error('Error fetching products:', error);
    }
}

async function commitOrder(uid, cartItems, address, promoCode) {
    let user = getCurrentUser();
    if (user != undefined) {
        uid = user.uid;
    } else {
        console.log("Not login");
        return false;
    }
    try {
        const order = {
            "uid": uid,
            "list": cartItems,
            "address": address,
            "promoCode": promoCode
        }
        console.log(order);
        const response = await fetch(`${API_URL}/myyOrder/commit`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(order),
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error commit order:', error);
        return false;
    }
}

async function getMyOrder(uid) {
    let user = getCurrentUser();
    if (user != undefined) {
        uid = user.uid;
    } else {
        console.log("Not login");
        return false;
    }
    try {
        const response = await fetch(`${API_URL}/myyOrder?uid=${uid}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error commit order:', error);
        return false;
    }
}

async function listAllOrder() {
    try {
        const response = await fetch(`${API_URL}/myyOrder/allOrders`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error commit order:', error);
        return false;
    }
}

async function getMyOrderWithOrderNumber(uid, orderNumber) {
    let user = getCurrentUser();
    if (user != undefined) {
        uid = user.uid;
    } else {
        console.log("Not login");
        return false;
    }
    try {
        const response = await fetch(`${API_URL}/myyOrder?uid=${uid}&orderNumber=${orderNumber}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error commit order:', error);
        return false;
    }
}

async function updateOrder(updateBody) {
    var temp = {};
    if (updateBody != undefined) {
        if (updateBody.orderId != undefined) {
            temp.orderId = updateBody.orderId;
        }
        if (updateBody.status != undefined) {
            temp.status = updateBody.status;
        }
        if (updateBody.remark != undefined) {
            temp.remark = updateBody.remark;
        }
        if (updateBody.logistics != undefined) {
            temp.logistics = updateBody.logistics;
        }
        if (updateBody.cancelItems != undefined) {
            temp.cancelItems = updateBody.cancelItems;
        }
        if (updateBody.payment != undefined) {
            temp.payment = updateBody.payment;
        }
        if (updateBody.discount != undefined) {
            temp.discount = updateBody.discount;
        }
    }
    try {
        const response = await fetch(`${API_URL}/myyOrder/updateOrder`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(temp)
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error update order:', error);
        return false;
    }
}

export {
    addMyList,
    getMyList,
    commitOrder,
    getMyOrder,
    getMyOrderWithOrderNumber,
    listAllOrder,
    updateOrder
};
