import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch, faCartShopping, faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import './BottomNavBar.css';

const BottomNavBar = () => {
    const [activeItem, setActiveItem] = useState('home');
    let navigate = useNavigate();

    const handleClick = (item) => {
        setActiveItem(item);
        if (item === "search") {
            navigate('/search');
        } else if (item === "home") {
            navigate('/');
        } else if (item === "bag") {
            navigate('/cart')
        } else if (item === "user") {
            navigate('/account')
        }
    };

    return (
        <nav className="bottom-nav">
            <div
                className={`bottom-nav-item ${activeItem === 'home' ? 'active' : ''}`}
                onClick={() => handleClick('home')}
            >
                <FontAwesomeIcon icon={faHome} />
                <span>Home</span>
            </div>
            <div
                className={`bottom-nav-item ${activeItem === 'search' ? 'active' : ''}`}
                onClick={() => handleClick('search')}
            >
                <FontAwesomeIcon icon={faSearch} />
                <span>Search</span>
            </div>
            <div
                className={`bottom-nav-item ${activeItem === 'bag' ? 'active' : ''}`}
                onClick={() => handleClick('bag')}
            >
                <FontAwesomeIcon icon={faCartShopping} />
                <span>Bag</span>
            </div>
            <div
                className={`bottom-nav-item ${activeItem === 'user' ? 'active' : ''}`}
                onClick={() => handleClick('user')}
            >
                <FontAwesomeIcon icon={faUser} />
                <span>Me</span>
            </div>
        </nav>
    );
};

export default BottomNavBar;
