import React, { useState } from 'react';
import './Signin.css';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { createUserWithEmailAndPassword, signInWithPopup, signInWithEmailAndPassword, GoogleAuthProvider } from "firebase/auth"

function SignUp() {
    let navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [activeTab, setActiveTab] = useState("signUp"); // New state
    const provider = new GoogleAuthProvider();

    const handleSignUp = () => {
        // Handle sign up logic here
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // The user has been signed up
                var user = userCredential.user;
                navigate("/account");
            })
            .catch((error) => {
                // An error occurred
                console.error(error);
            });
    };

    const handleLogIn = () => {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // The user is logged in
                var user = userCredential.user;
                navigate("/account");
            })
            .catch((error) => {
                // An error occurred
                console.error(error);
            });
    };

    const handleGoogleSignUp = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                // The signed-in user info.
                const user = result.user;
                console.log(user);
                navigate("/account");
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                console.error(errorCode, errorMessage, email, credential);
            });
    };

    const handleFacebookSignUp = () => {
        // Handle Facebook sign up logic here
    };

    return (
        <div className="signup-container">
            <div className="access-toggle" id="access-toggle">
                <button
                    id="signup-toggle"
                    className={`toggle-option ${activeTab === "signUp" ? "active" : ""}`}
                    role="tab"
                    onClick={() => setActiveTab("signUp")}
                >
                    Sign Up
                </button>
                <button
                    id="login-toggle"
                    className={`toggle-option ${activeTab === "logIn" ? "active" : ""}`}
                    role="tab"
                    onClick={() => setActiveTab("logIn")}
                >
                    Log In
                </button>
            </div>

            {activeTab === "signUp" ? <input
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
            /> : <div></div>}

            {activeTab === "signUp" ? <input
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
            /> : <div></div>}


            <input
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />

            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />

            <button onClick={activeTab === "signUp" ? handleSignUp : handleLogIn} className="signup-button">
                {activeTab === "signUp" ? "Sign Up " : "Login"}
            </button>

            <div className="term-text-containter">
                <span>By signing up, you agree you've read and accepted our <strong>Terms and Conditions</strong>. Please see out <strong>Privacy Policy</strong> for information on how we process your data.</span>

            </div>

            <div className="or-text-containter">
                <span>OR</span>
            </div>

            <button onClick={handleGoogleSignUp} className="google-signup-button">
                <img src="https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-webinar-optimizing-for-success-google-business-webinar-13.png" alt="Google logo" className="button-logo" />
                Continue with Google
            </button>

            <button onClick={handleFacebookSignUp} className="facebook-signup-button">
                <img src="https://www.freeiconspng.com/uploads/facebook-transparent-pics-18.png" alt="Facebook logo" className="button-logo" />
                Continue with Facebook
            </button>

            <div className="toggle-message">
                {activeTab === "signUp" ? "Already have an account? " : "Need an account? "}
                <span className="toggle-message-action">{activeTab === "signUp" ? "Log in" : "Sign up"}</span>
            </div>
        </div>
    );
}

export default SignUp;
