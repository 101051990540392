import React, { useEffect, useState } from 'react';
import API_URL from '../config/config';
import "./AdminSpuListPage.css"
import InfiniteScroll from 'react-infinite-scroll-component';
import AdminButtonRow from '../NavBar/AdminButtonNav';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AdminSpuList() {
    const [products, setProducts] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [selectedImage, setSelectedImage] = useState(null);

    const fetchProducts = async () => {
        try {
            const response = await fetch(`${API_URL}/spulist?brandId=231&page=${page}`);
            const data = await response.json();

            if (data.list.length === 0) {
                setHasMore(false); // No more products to load
            } else {
                // Filter out any products that are already in the state to avoid duplications
                const newProducts = data.list.filter(newProduct =>
                    !products.some(existingProduct => existingProduct.id === newProduct.id)
                );
                setProducts(prevProducts => [...prevProducts, ...newProducts]);
                setPage(prevPage => prevPage + 1); // Update page number for next API call
            }
        } catch (error) {
            console.error('Error fetching products:', error);
        } finally {
        }
    };

    const removeProduct = async (id) => {
        try {
            const response = await fetch(`${API_URL}/spulist/${id}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                setProducts(products.filter(product => product.id !== id));
                toast.info("Delete product success");
            } else {
                console.error('Error removing product:', await response.text());
                toast.error("Remove product error");
            }
        } catch (error) {
            console.error('Error removing product:', error);
            toast.error("Remove product error");
        }
    };

    const updateProduct = async (id, updatedProduct) => {
        try {
            const response = await fetch(`${API_URL}/spulist/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedProduct),
            });
            if (response.ok) {
                const updated = await response.json();
               // setProducts(products.map(product => (product.id === id ? updated : product)));
                toast.info("Edit product success");
            } else {
                console.error('Error updating product:', await response.text());
                toast.error("Edit product error");
            }
        } catch (error) {
            console.error('Error updating product:', error);
            toast.error("Edit product error");
        }
    };

    useEffect(() => {
        console.log("useEffect");
        fetchProducts(); // Initial load
    }, []);

    const handleImageRemove = (productId, imageIndex) => {
        setProducts(products.map(product =>
            product.id === productId ? {
                ...product,
                images: product.images.filter((_, idx) => idx !== imageIndex)
            } : product
        ));
    };

    const handleInputChange = (productId, field, value) => {
        setProducts(products.map(product =>
            product.id === productId ? { ...product, [field]: value } : product
        ));
    };

    const handleItemChange = (productId, itemId, field, value) => {
        setProducts(products.map(product =>
            product.id === productId ? {
                ...product,
                items: product.items.map(item =>
                    item._id === itemId ? { ...item, [field]: value } : item
                )
            } : product
        ));
    };

    const handleEditClick = (productId) => {
        const productToEdit = products.find(product => product.id === productId);
        if (productToEdit) updateProduct(productId, productToEdit);
    };

    const handleImageAdd = (productId) => {
        if (selectedImage) {
            setProducts(products.map(product =>
                product.id === productId ? {
                    ...product,
                    images: [...product.images, URL.createObjectURL(selectedImage)]
                } : product
            ));
            // Optionally: After adding the image, you can send an update to the server here.
        }
    };

    const handleStatusChange = (productId, value) => {
        
        setProducts(products.map(product =>
            product.id === productId ? { ...product, status: value } : product
        ));
    };

    return (
        <div className="admin-spu-list-container">
            {/*... other components */}
            <div className="admin-spu-list-header">
                <AdminButtonRow />
            </div>
            <InfiniteScroll
                dataLength={products.length} //This is important field to render the next data
                next={fetchProducts}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>Yay! You have seen it all</b>
                    </p>
                }
            >

                <div className="admin-spu-product-list">
                    {products.map((product) => (
                        <div key={product._id} className="admin-product-item">
                            <div className="product-details">
                                <div>
                                    <span>Name:
                                        <input
                                            type="text"
                                            value={product.name}
                                            onChange={(e) => handleInputChange(product.id, 'name', e.target.value)}
                                        />
                                    </span>
                            
                                </div>
                                <p className="product-desc">
                                    <textarea
                                        value={product.desc}
                                        onChange={(e) => handleInputChange(product.id, 'desc', e.target.value)}
                                    />
                                </p>
                                <div>
                                    <span>Retail Price:
                                        <input
                                            type="text"
                                            value={product.retailPrice}
                                            onChange={(e) => handleInputChange(product.id, 'retailPrice', e.target.value)}
                                        />
                                    </span>

                                </div>
                                <div className="product-images">
                                    {product.images.map((image, index) => (
                                        <div key={index} className="image-container">
                                            <img src={image} alt={product.name} />
                                            <button className="remove-button" onClick={() => handleImageRemove(product.id, index)}>Remove</button>
                                        </div>
                                    ))}
                                </div>
                                <div className="admin-product-status">
                                    <span>Status:
                                        <select
                                            value={product.status}
                                            onChange={(e) => handleStatusChange(product.id, e.target.value)}
                                        >
                                            <option value="1">Online</option>
                                            <option value="2">Offline</option>
                                        </select>
                                    </span>
                                </div>
                                <div className="product-sizes-prices">
                                    {product.items.map(item => (
                                        <div key={item._id} className="size-price">
                                            <span>Size:
                                                <input
                                                    type="text"
                                                    value={item.size}
                                                    onChange={(e) => handleItemChange(product.id, item._id, 'size', e.target.value)}
                                                />
                                            </span>
                                            <span>Price: $
                                                <input
                                                    type="number"
                                                    value={item.salePrice}
                                                    onChange={(e) => handleItemChange(product.id, item._id, 'salePrice', e.target.value)}
                                                />
                                            </span>
                                        </div>
                                    ))}
                                </div>
                                <div className="add-image-container">
                                    <input
                                        type="file"
                                        onChange={(e) => setSelectedImage(e.target.files[0])}
                                    />
                                    <button className="add-item-button" onClick={() => handleImageAdd(product.id)}>Add Images</button>
                                </div>
                                <button onClick={() => handleEditClick(product.id)}>Edit</button>
                                <button onClick={() => removeProduct(product.id)}>Remove</button>
                            </div>
                        </div>
                    ))}
                </div>
            </InfiniteScroll>
        </div>
    );

}

export default AdminSpuList;
