import React, { useState, useEffect } from 'react';
import { auth, getCurrentUser } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faHeart } from '@fortawesome/free-solid-svg-icons';
import './InfiniteProductList.css';
import API_URL from '../config/config';
import '../Loader.css';
const InfiniteProductList = (props) => {
    let navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [heartClickedProducts, setHeartClickedProducts] = useState([]);

    // Handle the heart icon click event for a specific product
    const handleHeartIconClick = (event, productId, product) => {
        event.stopPropagation(); // Stop the click event from propagating

        // Check if the product is already in the clicked list
        if (heartClickedProducts.includes(productId)) {
            // Remove the product ID from the list
            setHeartClickedProducts((prevClickedProducts) => prevClickedProducts.filter(id => id !== productId));
            addMyList(product, false);
        } else {
            // Add the product ID to the list
            setHeartClickedProducts((prevClickedProducts) => [...prevClickedProducts, productId]);
            addMyList(product, true);
        }
    };
    // Handle the product item click event
    const handleProductItemClick = (productId) => {
        navigate("/product/" + productId, { state: { product: productId, brand: props.props.activePage } });
    };

    const fetchProducts = async (reload = false) => {
        console.log("fetchProducts");
        var targetPage = page;
        if (reload) {
            targetPage = 1;
        }
        try {
            const response = await fetch(`${API_URL}/spulist?status=1&page=${targetPage}&brandId=${props.props.brandId}&categoryId=${props.props.categoryId}&priceAsc=${props.props.priceAsc}&priceDesc=${props.props.priceDesc}`);
            const data = await response.json();
            data.list.forEach(element => {
                element.url = element.images[0];
                element.title = element.name;
            });

            if (data.length === 0) {
                setHasMore(false);
                return;
            }

            if (reload) {
                setProducts(data.list);
            } else {
                // Filter out duplicated products using a Set
                const uniqueProducts = new Set(products.map(product => product.productId));
                const filteredData = data.list.filter(product => !uniqueProducts.has(product.productId));
                setProducts((prevProducts) => [...prevProducts, ...filteredData]);
            }

            setPage(targetPage + 1);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };


    async function addMyList(product, isAdded) {
        try {
            let user = getCurrentUser();
            if (user != undefined) {
                const uid = user.uid;
                const brandIDResponse = await fetch(`${API_URL}/brandlist?brandId=${product.brandId}`);
                const data = await brandIDResponse.json();
                const brand = data.list[0].englishName;

                const temp = {
                    "uid": uid,
                    "list": [
                        {
                            "productId": product.productId,
                            "brandId": product.brandId,
                            "brand": brand,
                            "thumbnail": product.images[0],
                            "description": product.name,
                            "size": "",
                            "price": product.retailPrice,
                            "updatedDate": Date.now()
                        }
                    ]
                }

                const myListResponse = await fetch(`${API_URL}/mylist?uid=${uid}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                const myListResp = await myListResponse.json();
                let myList = myListResp.myList[0];
                console.log(myList);

                if (myList === undefined) {
                    if (!isAdded) { return; }
                    const response = await fetch(`${API_URL}/mylist`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(temp),
                    });
                } else {
                    const myListIndex = myList.list.findIndex((list) => list.productId == product.productId);
                    if (myListIndex !== -1) {
                        if (!isAdded) {
                            myList.list.splice(myListIndex, 1);
                        } else {
                            return;
                        }
                    } else {
                        if (isAdded) {
                            myList.list.push(temp.list[0]);
                        }
                    }
                    const response = await fetch(`${API_URL}/mylist`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(myList),
                    });
                }

            }
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    }

    async function getMyList(uid) {
        const myListResponse = await fetch(`${API_URL}/mylist?uid=${uid}`, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        const myListResp = await myListResponse.json();
        let myList = myListResp.myList[0];
        return myList;
    }

    useEffect(() => {
        reloadProducts();
    }, [props]);

    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            console.log("onAuthStateChanged user: ", user);
            if (getCurrentUser() != undefined) {
                let obj = await getMyList(getCurrentUser().uid);
                setHeartClickedProducts([]);
                try {
                    var list = [];
                    for (var i = 0; i < obj.list.length; i++) {
                        list.push(obj.list[i].productId);
                    }
                    setHeartClickedProducts(list);
                } catch (err) {
                    console.error(err);
                }
            }
        });

        // Cleanup function to unsubscribe to the listener when the component unmounts
        return () => unsubscribe();
    }, []); // Empty dependency array ensures this effect runs only once on mount


    const reloadProducts = async () => {
        setProducts([]);
        setPage(1); // Reset the page state to 1
        setHasMore(true);
        console.log("reload Products");
        fetchProducts(true);
    };

    const handleOverlayClick = (productId) => {
        console.log(`Overlay clicked for product ${productId}`);
        // Add your custom logic here for handling the click event
    };

    const LoadingSpinner = () => {
        return (
            <div className="loading-spinner-overlay">
                <div className="spinner"></div>
            </div>
        );
    };

    function timeAgo(time) {
        const currentTime = new Date().getTime() / 1000; // Current time in seconds

        // If the time value is likely in milliseconds (e.g., it's a very large number), convert it to seconds
        if (time > 1000000000000) time /= 1000;

        const difference = currentTime - time;

        const secondsInHour = 3600;
        const secondsInDay = 86400; // 24 hours
        const secondsInWeek = 604800; // 7 days
        const secondsInMonth = 2592000; // Assume 30 days in a month

        if (difference < secondsInHour) {
            return '1 小時前';
        } else if (difference < 4 * secondsInHour) {
            return '4 小時前';
        } else if (difference < 12 * secondsInHour) {
            return '12 小時前';
        } else if (difference < 1 * secondsInDay) {
            return '1 日前';
        } else if (difference < 2 * secondsInDay) {
            return '2 日前';
        } else if (difference < 3 * secondsInDay) {
            return '3 日前';
        } else if (difference < 4 * secondsInDay) {
            return '4 日前';
        } else if (difference < 5 * secondsInDay) {
            return '5 日前';
        } else if (difference < 6 * secondsInDay) {
            return '6 日前';
        } else if (difference < 7 * secondsInDay) {
            return '7 日前';
        } else if (difference < 1 * secondsInWeek) {
            return '1 星期前';
        } else if (difference < 2 * secondsInWeek) {
            return '2 星期前';
        } else if (difference < 3 * secondsInWeek) {
            return '3 星期前';
        } else if (difference < 1 * secondsInMonth) {
            return '1 個月前';
        } else if (difference < 2 * secondsInMonth) {
            return '2 個月前';
        } else if (difference < 3 * secondsInMonth) {
            return '3 個月前';
        } else if (difference < 4 * secondsInMonth) {
            return '4 個月前';
        } else {
            return '大於 4 個月';
        }
    }


    return (
        <div className='infiniteScrollContainer'>
            <h2>{props.props.activePage}</h2>
            <InfiniteScroll
                dataLength={products.length}
                next={fetchProducts}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
            >
                <div className="product-list">
                    {products.map((product) => (
                        <div key={product.id} className="product-item" onClick={() => handleProductItemClick(product.productId)}>
                            <div className="product-image-wrapper">
                                <img
                                    src={`${product.url}`}
                                    alt={`Product ${product.id}`}
                                    className="product-image"
                                />
                                {/* <div className="overlay">
                                    <FontAwesomeIcon icon={faEllipsisH} className="overlay-icon" onClick={() => handleOverlayClick(product.id)} />
                                </div> */}
                                <span className="overlay-text">更新於 {timeAgo(product.updatedTime / 1000)}</span>
                                <div className="heart-icon-wrapper" onClick={(event) => handleHeartIconClick(event, String(product.productId), product)}>
                                    <FontAwesomeIcon icon={faHeart} className={`heart-icon ${heartClickedProducts.includes(String(product.productId)) ? 'red' : ''}`} />
                                </div>
                            </div>
                            <div className="product-details">
                                <h4>{product.title}</h4>
                                <div className="product-pricing">
                                    <span className="product-price">
                                        HKD $ {product.retailPrice.toFixed(2)}
                                    </span>

                                    {product.discount > 0 && (
                                        <span className="product-discount">
                                            <s className="original-price">$ {product.retailPrice.toFixed(2)}</s>
                                            {/* Display the discounted price */}
                                            <span className="discounted-price">
                                                $ {((product.retailPrice * product.discount / 100)).toFixed(2)}
                                            </span>
                                        </span>
                                    )}
                                </div>
                                {product.discount < 100 && product.discount > 0 ? <span className="discount-tag">低至 {product.discount / 10}折</span> : null}
                                {product.totalStock === 0 ? <span className="sold-out">SOLD OUT</span> : null}
                            </div>
                            {
                                product.sizeArray && product.sizeArray.length > 0 && (
                                    <div className="product-sizes">
                                        {product.sizeArray.map((size, index) => (
                                            <span key={index} className="product-size">{size}</span>
                                        ))}
                                    </div>
                                )
                            }
                        </div>
                    ))}
                </div>
            </InfiniteScroll >
        </div >
    );
};

export default InfiniteProductList;
