import React from 'react';
import { useNavigate } from 'react-router-dom';
function AdminButtonRow() {
    const navigate = useNavigate();
    return (
        <div style={{ display: 'flex', gap: '10px' }}>
            <button onClick={() => navigate('/adminPage')}>Admin</button>
            <button onClick={() => navigate('/adminSpuPage')}>Create SPU</button>
            <button onClick={() => navigate('/adminSpuListPage')}>SPU List</button>
            <button onClick={() => navigate('/adminOrderIMPage')}>Order IM</button>
            <button onClick={() => navigate('/adminOrderIMPage')}>IM List</button>
        </div>
    );
}

export default AdminButtonRow;
