import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './MyList.css';
import API_URL from '../config/config';
import { auth } from '../firebase';
import { onAuthStateChanged } from "firebase/auth"; // Import getAuth
import InfiniteScroll from 'react-infinite-scroll-component';
import '../Loader.css';
// Create a separate loading spinner component
const LoadingSpinner = () => {
    return (
        <div className="loading-spinner-overlay">
            {/* Add your custom loading spinner component here */}
            {/* For example, you can use a div with a CSS spinner */}
            <div className="spinner"></div>
        </div>
    );
};

function MyListPage() {
    const navigate = useNavigate();
    const [myList, setMyList] = useState([]);
    const [showLoading, setShowLoading] = useState(false); // Add showLoading state variable

    const handleBackClick = () => {
        navigate("/account");
    };

    async function removeMyList(productId) {
        try {
            setShowLoading(true); // Show loading spinner when removal process starts

            let user = auth.currentUser;
            if (user != undefined) {
                const uid = user.uid;
                const myListResponse = await fetch(`${API_URL}/mylist?uid=${uid}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                const myListResp = await myListResponse.json();
                let myList = myListResp.myList[0];

                if (myList === undefined) {
                    return;
                } else {
                    const myListIndex = myList.list.findIndex((list) => list.productId == productId);
                    if (myListIndex !== -1) {
                        myList.list.splice(myListIndex, 1);
                    } else {
                        return;
                    }
                    const response = await fetch(`${API_URL}/mylist`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(myList),
                    });
                    setMyList(myList.list);
                    setShowLoading(false); // Hide loading spinner when removal process is completed
                }

            }
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    }

    const handleRemoveClick = (productId) => {
        console.log("Handle Remove Click");
        removeMyList(productId);
    };

    const fetchMyList = async () => {
        try {
            const response = await fetch(`${API_URL}/mylist?uid=${auth.currentUser.uid}`);
            const data = await response.json();
            const myList = data.myList[0].list;
            setMyList(myList);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    }


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log("onAuthStateChanged user: ", user);
            fetchMyList();
        });
        // Cleanup function to unsubscribe to the listener when the component unmounts
        return () => unsubscribe();
    }, []); // Empty dependency array ensures this effect runs only once on mount


    return (
        <div className="account-page">
            {showLoading && <LoadingSpinner />}
            <div className="header">
                <button className="backButton" onClick={handleBackClick}>Back</button>
            </div>
            <h2>My List</h2>
            <div className="account-container">
                <InfiniteScroll
                    dataLength={myList.length}
                    loader={<h4>Loading...</h4>}
                >
                    <div className="myList-list">
                        {myList.map((list) => (
                            <div key={myList.productId} className="myList-item" >
                                <div className="product-image-wrapper">
                                    <img
                                        src={`${list.thumbnail}`}
                                        alt={`Product ${list.productId}`}
                                        className="myList-image"
                                    />
                                </div>
                                <div>
                                    <h3>{list.brand}</h3>
                                    <p>{list.description}</p>
                                    <span className="product-price">€{list.price}</span>
                                </div>
                                <button className="save-changes-button">Add to cart</button>
                                <button className="save-changes-button" onClick={() => handleRemoveClick(list.productId)}>Remove</button>
                            </div>
                        ))}
                    </div>
                </InfiniteScroll>
            </div>
        </div>
    );
}

export default MyListPage;