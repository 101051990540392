import React, { useState } from 'react';
import './HomePage.css';
import HeroCarousel from './HeroCarousel/HeroCarousel';
import InfiniteProductList from '../ProductPage/InfiniteProductList';
import ButtonSlider from '../NavBar/ButtonSlider';
import ProductFilter from '../NavBar/ProductFilter';
import brandsConst from '../constant/brandname';
import SearchRow from '../SearchPage/SearchRow';
import FloatingChatChannel from '../Channel/FloatingChatChannel';

function HomePage(props) {

    const sizes = ['XS', 'S', 'M', 'L', 'XL', 'XXL', '37', '38', '39'];
    const brands = brandsConst;

    const [selectedSizes, setSelectedSizes] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState('Gucci');
    const [selectedMinPrice, setSelectedMinPrice] = useState(0);
    const [selectedMaxPrice, setSelectedMaxPrice] = useState(1000);
    const [filtersVisible, setFiltersVisible] = useState(false); // State for toggling filters visibility

    const handleSizeChange = (size) => {
        if (selectedSizes.includes(size)) {
            setSelectedSizes((prevSizes) => prevSizes.filter((item) => item !== size));
        } else {
            setSelectedSizes((prevSizes) => [...prevSizes, size]);
        }
    };

    const handleBrandChange = (brand) => {
        if (brands.includes(brand)) {
            console.log("handle brand change: " + brand);
            setSelectedBrand(brand);
            props.handleBrandChange(brand);
        }
    }

    const handleMinPriceChange = (minPrice) => {
        setSelectedMinPrice(minPrice);
    };

    const handleMaxPriceChange = (maxPrice) => {
        setSelectedMaxPrice(maxPrice);
    };


    const applyFilters = (selectedSizes) => {
        // You can implement the logic to filter products based on selected sizes and price here
        console.log('Selected Sizes:', selectedSizes);
        console.log('Selected Price:', selectedMinPrice, selectedMaxPrice);
    };

    const clearFilters = () => {
        setSelectedSizes([]);
        setSelectedMinPrice(0);
        setSelectedMaxPrice(1000);
    };

    // Function to toggle the visibility of the filters-container
    const toggleFilters = () => {
        setFiltersVisible((prevValue) => !prevValue);
    };

    const closeModal = () => {
        setFiltersVisible(false);
    }

    return (
        <div>
            <div className="homepage-container">
                {/* <ButtonSlider props={props} /> */}
                <FloatingChatChannel />
                <HeroCarousel />
                <p className="marvoo-slogan-1">「正版正貨承諾」計劃網店 - 會員編號: 2023254 </p>
                <p className="marvoo-slogan-2">Marvoo 買手團隊長駐歐洲代購貨品</p>

                <SearchRow
                    handleMenuClick={props.handleMenuClick}
                    handlePriceFilterClick={props.handlePriceFilterClick}
                    handleCategoryClick={props.handleCategoryClick}
                />
                {filtersVisible ? (
                    <div className="modal-overlay" onClick={toggleFilters}>
                        <div className="modal-content" onClick={e => e.stopPropagation()}>
                            <ProductFilter
                                sizes={sizes}
                                brands={brands}
                                selectedSizes={selectedSizes}
                                closeModal={closeModal}
                                selectedMinPrice={selectedMinPrice}
                                selectedMaxPrice={selectedMaxPrice}
                                applyFilters={applyFilters}
                                clearFilters={clearFilters}
                                handleSizeChange={handleSizeChange}
                                handleMinPriceChange={handleMinPriceChange}
                                handleMaxPriceChange={handleMaxPriceChange}
                                handleBrandChange={handleBrandChange}
                            />
                        </div>
                    </div>
                ) : null}
                <InfiniteProductList props={props} />
            </div>
        </div >
    );
}

export default HomePage;
