import React, { useState } from 'react';
import './SearchRow.css'; // Import your CSS file for styling
import brandsConst from '../constant/brandname';
const SearchRow = ({ handlePriceFilterClick, handleMenuClick, handleCategoryClick }) => {
    const categories = [
        '夹克/外套',      //1576
        '卫衣/针织衫',    //1578
        '上衣',          //1582
        '靴子/高帮鞋',    //1586
        '高跟鞋',        //1594
        '凉鞋/凉拖',      //1595
        '休闲/运动鞋',    //1597
        '双肩包',        //1599
        '手拿包/迷你包',  //1600
        '单肩包/斜挎包',  //1604
        '钱包',         //1605
        '皮带/腰带',    //1609
        '太阳镜',      //1610
        '帽子',        //1613
        '围巾/丝巾',   //1618
        '手提包',      //1620
        '大衣/羽绒服',  //1645
        '平底鞋/便鞋',   //1646
        '坡跟/松糕鞋',    //1648
        '旅行包',       //1649
        '首饰'        //1650
    ];

    const [activeButton, setActiveButton] = useState(null);
    const [showBrandDropdown, setShowBrandDropdown] = useState(false);
    const [showCategoryDropdown, setCategoryDropdown] = useState(false);
    const [showRankDropdown, setRankDropdown] = useState(false);

    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedRankings, setSelectedRankings] = useState([]);

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
        if (buttonName === "brand") {
            setShowBrandDropdown(!showBrandDropdown);
            setCategoryDropdown(false);
            setRankDropdown(false);
        } else if (buttonName === "category") {
            setShowBrandDropdown(false);
            setCategoryDropdown(!showCategoryDropdown);
            setRankDropdown(false);
        } else {
            setShowBrandDropdown(false);
            setCategoryDropdown(false);
            setRankDropdown(!showRankDropdown);
        }
    };

    const handleBrandDropdownClick = (brand) => {
        // Handle the selection of a brand from the dropdown
        console.log("Selected brand:", brand);
        handleMenuClick(brand);
        setSelectedBrands([]);
        setSelectedBrands((prevSelected) => {
            if (prevSelected.includes(brand)) {
                return prevSelected.filter((item) => item !== brand);
            } else {
                return [...prevSelected, brand];
            }
        });
    };

    const handleCategoryDropdownClick = (category) => {
        // Handle the selection of a brand from the dropdown
        console.log("Selected category:", category);
        handleCategoryClick(category);
        setSelectedCategories([]);
        setSelectedCategories((prevSelected) => {
            if (prevSelected.includes(category)) {
                return prevSelected.filter((item) => item !== category);
            } else {
                return [...prevSelected, category];
            }
        });
    }

    const handleRankingDropdownClick = (ranking) => {
        console.log("Selected ranking: ", ranking);
        setSelectedRankings([]);
        setSelectedRankings((prevSelected) => {
            if (prevSelected.includes(ranking)) {
                return prevSelected.filter((item) => item !== ranking);
            } else {
                return [...prevSelected, ranking];
            }
        });
        handlePriceFilterClick(ranking);
    }

    const isItemSelected = (itemName, dropdownName) => {
        if (dropdownName === "brand") {
            return selectedBrands.includes(itemName);
        } else if (dropdownName === "category") {
            return selectedCategories.includes(itemName);
        } else {
            return selectedRankings.includes(itemName);
        }
    };

    return (
        <div className="dropdown-menu-buttons">
            <button
                className={`menu-button ${activeButton === 'brand' ? 'active' : ''}`}
                onClick={() => handleButtonClick('brand')}
            >
                牌子
            </button>
            {showBrandDropdown && activeButton === "brand" && (
                <div className="brand-dropdown">
                    {brandsConst.map((brand, index) => (
                        <button
                            key={index}
                            onClick={() => handleBrandDropdownClick(brand)}
                            className={isItemSelected(brand, "brand") ? 'active' : ''}
                        >
                            {brand}
                        </button>
                    ))}
                </div>
            )}
            <button
                className={`menu-button ${activeButton === 'category' ? 'active' : ''}`}
                onClick={() => handleButtonClick('category')}
            >
                類別
            </button>
            {showCategoryDropdown && activeButton === "category" && (
                <div className="category-dropdown">
                    {categories.map((category, index) => (
                        <button
                            key={index}
                            onClick={() => handleCategoryDropdownClick(category)}
                            className={isItemSelected(category, "category") ? 'active' : ''}
                        >
                            {category}
                        </button>
                    ))}
                </div>
            )}
            <button
                className={`menu-button ${activeButton === 'sort' ? 'active' : ''}`}
                onClick={() => handleButtonClick('ranking')}
            >
                排序
            </button>
            {showRankDropdown && activeButton === "ranking" && (
                <div className="ranking-dropdown">
                    <button onClick={() => handleRankingDropdownClick("updateTime")} className={isItemSelected("updateTime", "ranking") ? 'active' : ''}>更新時間</button>
                    <button onClick={() => handleRankingDropdownClick("priceAsc")} className={isItemSelected("priceAsc", "ranking") ? 'active' : ''}>價錢由低至高</button>
                    <button onClick={() => handleRankingDropdownClick("priceDesc")} className={isItemSelected("priceDesc", "ranking") ? 'active' : ''}>價錢由高至低</button>
                </div>
            )}
        </div>
    );
};

export default SearchRow;
