// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth"; // Import getAuth

const firebaseConfig = {
    apiKey: "AIzaSyBgrd_lYZXUdSG5UqPgesY0lXJebwmf-HA",
    authDomain: "marvoo.firebaseapp.com",
    projectId: "marvoo",
    storageBucket: "marvoo.appspot.com",
    messagingSenderId: "326403690161",
    appId: "1:326403690161:web:dd2ded05fb5fc156955c90",
    measurementId: "G-DQBVD5LYG8"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

let currentUser = null;

const setCurrentUser = (user) => {
    currentUser = user;
};

const getCurrentUser = () => {
    console.log(currentUser);
    return currentUser;
};

onAuthStateChanged(auth, (user) => {
    if (user) {
        // User is signed in, set the current user
        console.log("user: ", user);
        setCurrentUser(user);
    } else {
        // User is signed out, set the current user to null
        setCurrentUser(null);
    }
});


export { auth, analytics, getCurrentUser };
