import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './AddressBook.css';
import Modal from 'react-modal';
import API_URL from '../config/config';
import { auth } from '../firebase';
import { onAuthStateChanged } from "firebase/auth"; // Import getAuth
import { toast } from 'react-toastify';

Modal.setAppElement('#root'); // This line is important for accessibility purposes

function AddressBook() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [editAddress, setEditAddress] = useState({});
    const [profile, setProfile] = useState({});
    const [addresses, setAddresses] = useState([]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log("onAuthStateChanged user: ", user);
            fetchAddress();
        });
        // Cleanup function to unsubscribe to the listener when the component unmounts
        return () => unsubscribe();
    }, []); // Empty dependency array ensures this effect runs only once on mount


    const fetchAddress = async () => {
        try {
            const response = await fetch(`${API_URL}/profile?uid=${auth.currentUser.uid}`);
            const data = await response.json();
            setProfile(data.profile[0]);
            setAddresses(data.profile[0].addresses);

        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };


    const createOrUpdateProfile = async (temp) => {
        const response = await fetch(`${API_URL}/profile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(temp),
        });

        return await response.json();
    };

    const editAddressAPI = async () => {
        try {
            if (profile === undefined) {
                const temp = {
                    _id: auth.currentUser.uid,
                    uid: auth.currentUser.uid,
                    addresses: [editAddress]
                };

                const data = await createOrUpdateProfile(temp);
                navigate("/addressBook");
            } else {
                const addressIndex = profile.addresses.findIndex((address) => address._id === editAddress._id);

                if (addressIndex !== -1) {
                    profile.addresses[addressIndex] = editAddress;
                } else {
                    profile.addresses.push(editAddress);
                }

                const data = await createOrUpdateProfile(profile);
            }
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#f5f5f5',
            padding: '20px',
            borderRadius: '4px',
            width: '50%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }
    };

    const [isAddNew, setIsAddNew] = useState(false);

    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate("/account");
    };

    const handleAddNewAddress = () => {
        setEditAddress({});
        setIsAddNew(true);
        setModalIsOpen(true);
    }

    const openModal = (address) => {
        setEditAddress(address);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleEdit = () => {
        editAddressAPI();
        closeModal();
        if (isAddNew) toast.info("Added address");
        else toast.info("Address Changed");
        navigate("/addressBook");
    }

    const handleDelete = async (address) => {
        console.log("Delete");
        const confirmBox = window.confirm(
            "Do you really want to delete this address?"
        )
        if (confirmBox === true) {
            try {
                const updatedAddresses = profile.addresses.filter((addr) => addr._id !== address._id);

                const response = await fetch(`${API_URL}/profile`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ ...profile, addresses: updatedAddresses }),
                });

                const data = await response.json();
                console.log(data);
                profile.addresses = updatedAddresses;
                setProfile(profile);
                setAddresses(updatedAddresses);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        }
    }

    const handleChange = (e) => {
        setEditAddress({
            ...editAddress,
            [e.target.name]: e.target.value
        });
    }

    return (
        <div className="order-page-container">
            <div className="header">
                <button className="backButton" onClick={handleBackClick}>Back</button>
            </div>
            <h2>Address Book</h2>

            <div className="order-list">
                {addresses.map(address => (
                    <div className="order-card">
                        <div className="order-info">
                            <div className="order-row">
                                <small className="order-row-first">Address:</small>
                                <small>{address.address1}</small>
                            </div>
                            <div className="order-row">
                                <small className="order-row-first"></small>
                                <small>{address.address2}</small>
                            </div>
                            <div className="order-row">
                                <small className="order-row-first">Recipient:</small>
                                <small>{address.recipient}</small>
                            </div>
                            <div className="order-row">
                                <small className="order-row-first">Contact Number:</small>
                                <small>{address.contact}</small>
                            </div>
                            <div className="button-container1">
                                <button className='address-button' onClick={() => { setIsAddNew(false); openModal(address); }}>Edit</button>
                                <button className='address-button' onClick={() => { handleDelete(address); }}>Delete</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <button onClick={handleAddNewAddress} className="save-changes-button">Add new address</button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Edit Address"
            >
                <h4>{isAddNew ? "Add New Address" : "Edit Address"}</h4>
                <form>
                    <div className="form-row">
                        <label htmlFor="address1">Address Line 1:</label>
                        <input id="address1" name="address1" value={editAddress.address1} onChange={handleChange} />
                    </div>

                    <div className="form-row">
                        <label htmlFor="address2">Address Line 2:</label>
                        <input id="address2" name="address2" value={editAddress.address2} onChange={handleChange} />
                    </div>

                    <div className="form-row">
                        <label htmlFor="address3">Recipient:</label>
                        <input id="recipient" name="recipient" value={editAddress.recipient} onChange={handleChange} />
                    </div>

                    <div className="form-row">
                        <label htmlFor="contactNumber">Contact Number:</label>
                        <input id="contact" name="contact" value={editAddress.contact} onChange={handleChange} />
                    </div>
                    <div className="button-group">
                        <button class="custom-button save-button" type="button" onClick={handleEdit}>
                            {isAddNew ? "Save" : "Save"}
                        </button>
                        <button class="custom-button cancel-button" type="button" onClick={closeModal}>
                            Cancel
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    );
}

export default AddressBook;