import React from 'react';
import "./FloatingChatChannel.css";

function FloatingChatChannel() {
    const handleWhatsAppClick = () => {
        // Replace '1234567890' with the recipient's phone number
        const phoneNumber = '+85296798860';

        // Replace 'Hello%20world!' with the URL-encoded message
        const message = 'Welcome to marvoo';

        // Construct the WhatsApp link
        const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;

        // Open WhatsApp in a new tab/window
        window.open(whatsappLink, '_blank');
    };

    return (
        <div className="floating-chat-channel">
            <button className="whatsapp-button" onClick={handleWhatsAppClick}>
                <img src="https://files.keyreply.com/files/images/app/whatsapp.svg" alt="WhatsApp" />
            </button>
        </div>
    );
}

export default FloatingChatChannel;
