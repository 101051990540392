import React, { useState, useEffect } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import './Account.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

function AccountPage() {
    let navigate = useNavigate();
    const [user, setUser] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log("onAuthStateChanged user: ", user);
            if (user) {
                setUser(user);
            } else {
                // User is signed out, set the current user to null
                setUser(null);
            }
        });

        // Cleanup function to unsubscribe to the listener when the component unmounts
        return () => unsubscribe();
    }, []); // Empty dependency array ensures this effect runs only once on mount

    const handleAccountInfo = () => {
        navigate("/accountInfo");
    }

    const handleOrder = () => {
        navigate("/order");
    }

    const handleAddressBook = () => {
        navigate("/addressBook");
    }

    const handleMyList = () => {
        navigate("/mylist");
    }

    const handleLogout = () => {
        const confirmBox = window.confirm(
            "Do you really want to log out?"
        )
        if (confirmBox === true) {
            // Log out the user
            auth.signOut().then(() => {
                console.log('User logged out');
                navigate("/signup");
            }).catch((error) => {
                console.error('Error logging out:', error);
            });
        }
    };

    if (!user) {
        return (
            <div className="account-page">
                <h1>Account Page</h1>
                <span>Loading.....</span>
            </div>
        );
    }

    return (
        <div className="account-page">
            <h1>Account Page</h1>
            <div className="account-container">
                <table className="account-table">
                    <tbody>
                        <tr>
                            <td onClick={handleAccountInfo}>Account Info</td>
                        </tr>
                        <tr>
                            <td onClick={handleOrder}>Order History</td>
                        </tr>
                        <tr>
                            <td onClick={handleAddressBook}>Address Book</td>
                        </tr>
                        <tr>
                            <td onClick={handleMyList}>My List</td>
                        </tr>
                        <tr>
                            <td onClick={handleLogout}>Logout</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>Display Name:</strong> {user.displayName || "None"}</p>

        </div>
    );
}

export default AccountPage;
