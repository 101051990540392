import React, { useEffect, useState } from 'react';
import { listAllOrder, updateOrder } from '../API/api'; // Replace with your actual API functions
import './AdminOrderPage.css';
import AdminButtonRow from '../NavBar/AdminButtonNav';

function AdminOrderPage() {
    const [orders, setOrders] = useState([]);
    const [newStatus, setNewStatus] = useState('');
    const [logisticsInfo, setLogisticsInfo] = useState('');
    const [remark, setRemark] = useState('');
    const [payment, setPayment] = useState('');
    const [discount, setDiscount] = useState(0);

    useEffect(() => {
        // Fetch orders on component mount
        fetchOrders();
    }, []);

    const fetchOrders = async () => {
        try {
            const data = await listAllOrder(); // Replace with your API call
            setOrders(data.orderList);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    const handleStatusChange = async (order) => {
        try {
            if (newStatus.trim() !== '') {
                const response = await updateOrder(
                    {
                        orderId: order._id,
                        status: newStatus
                    }
                )
                console.log(response);
                fetchOrders();
            }
        } catch (error) {
            console.error('Error updating order status:', error);
        }
    };

    const handleLogisticsSubmit = async (order) => {
        try {
            if (logisticsInfo.trim() !== '') {
                const response = await updateOrder(
                    {
                        orderId: order._id,
                        logistics: logisticsInfo
                    }
                )
                fetchOrders();
            }
        } catch (error) {
            console.error('Error submitting logistics info:', error);
        }
    };

    const handleRemarkSubmit = async (order) => {
        try {
            if (remark.trim() !== '') {
                const response = await updateOrder(
                    {
                        orderId: order._id,
                        remark: remark
                    }
                )
                fetchOrders();
            }
        } catch (error) {
            console.error('Error submitting remark info:', error);
        }
    };

    const handlePaymentChange = async (order) => {
        try {
            if (payment.trim() !== '') {
                const response = await updateOrder(
                    {
                        orderId: order._id,
                        payment: payment
                    }
                )
                fetchOrders();
            }
        } catch (error) {
            console.error('Error submitting payment info:', error);
        }
    }

    const handleDiscountChange = async (order) => {
        try {
            const response = await updateOrder(
                {
                    orderId: order._id,
                    discount: discount
                }
            )
            fetchOrders();
        } catch (error) {
            console.error('Error submitting discount:', error);
        }
    }

    return (
        <div className="admin-order-page">
            <AdminButtonRow />
            <h2>Admin Order Management</h2>
            <div className="order-list">
                {orders.map(order => (
                    <div className="order-card">
                        <div className="order-info">
                            <div className="order-row"><small>Order Number:</small> <small>{order._id}</small></div>
                            <div className="order-row"><small>Order Date:</small> <small>{order.createDateHK}</small></div>
                            <div className="order-row"><small>Last Update Date:</small> <small>{order.updatedDateHK}</small></div>
                            <div className="order-row"><small>Address1:</small> <small>{order.address.address1}</small></div>
                            <div className="order-row"><small>Address1:</small> <small>{order.address.address2}</small></div>
                            <div className="order-row"><small>Recipient:</small> <small>{order.address.recipient}</small></div>
                            <div className="order-row"><small>Contact:</small> <small>{order.address.contact}</small></div>
                            <div className="order-row"><small>Remark:</small> <small>{order.remark}</small></div>
                            <div className="order-row"><small>Promo Code:</small> <small>{order.promoCode}</small></div>
                            <div className="order-row"><small>Discount:</small> <small>{order.discount}</small></div>
                            <div className="order-row"><small>Logistics:</small> <small>{order.logistics}</small></div>
                            <div className="order-row"><small>Payment:</small> <strong>{order.payment}</strong></div>
                            <div className="order-row"><small>Status:</small> <strong>{order.status}</strong></div>
                            <div className="order-row"><small>Price:</small> <strong>HKD ${order.totalPrice}</strong></div>
                        </div>
                        <div className="admin-change-status">
                            <select value={newStatus} onChange={(e) => setNewStatus(e.target.value)}>
                                <option value="">Select Status</option>
                                <option value="Pending">Pending</option>
                                <option value="Processing">Processing</option>
                                <option value="Shipped">Shipped</option>
                                <option value="Completed">Completed</option>
                                <option value="Cancel">Cancel</option>
                                <option value="Refund">Refund</option>
                            </select>
                            <button onClick={() => handleStatusChange(order)}>Update Status</button>
                        </div>
                        <div className="admin-add-logistics-id">
                            <input
                                className='admin-page-input'
                                type="text"
                                placeholder="Logistics ID"
                                value={logisticsInfo}
                                onChange={(e) => setLogisticsInfo(e.target.value)}
                            />
                            <button onClick={() => handleLogisticsSubmit(order)}>Update Logistics</button>
                        </div>
                        <div className="admin-add-logistics-id">
                            <input
                                className='admin-page-input'
                                type="text"
                                placeholder="Payment"
                                value={payment}
                                onChange={(e) => setPayment(e.target.value)}
                            />
                            <button onClick={() => handlePaymentChange(order)}>Update Payment</button>
                        </div>
                        <div className="admin-add-logistics-id">
                            <input
                                className='admin-page-input'
                                type="float"
                                placeholder="Discount"
                                value={discount}
                                onChange={(e) => setDiscount(e.target.value)}
                            />
                            <button onClick={() => handleDiscountChange(order)}>Update Discount</button>
                        </div>
                        <div className="admin-add-logistics-id">
                            <input
                                className='admin-page-input'
                                type="text"
                                placeholder="Remark"
                                value={remark}
                                onChange={(e) => setRemark(e.target.value)}
                            />
                            <button onClick={() => handleRemarkSubmit(order)}>Update Remark</button>
                        </div>
                        <table className="itemsContainer">
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    order.list.map((item, index) => (
                                        <tr key={index}>
                                            <td><img className="order-detail-image" src={item.product.images[0]} /></td>
                                            <td>
                                                <div className="order-row"><small>Brand:</small> <small>{item.brand}</small></div>
                                                <div className="order-row"><small>Name:</small> <small>{item.product.name}</small></div>
                                                <div className="order-row"><small>ItemId:</small> <small>{item.selectedItem.itemId}</small></div>
                                                <div className="order-row"><small>salePrice:</small> <small>{item.selectedItem.salePrice}</small></div>
                                                <div className="order-row"><small>size:</small> <small>{item.selectedItem.size}</small></div>
                                            </td>
                                            <td>{item.size}</td>
                                            <td>{item.price}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                ))}
            </div>
        </div >
    );
}

export default AdminOrderPage;
