import React from 'react';
import { Link } from 'react-router-dom';
import BrandButton from './BrandButton';
import './SearchPage.css';

const brands = ['GUCCI', 'Alexdendar McQueen', 'JIMMY CHOO', 'Chloe', 'Alexdendar McQueen'];

const SearchPage = () => {
    return (
        <div className="search-page">
            <div className="brand-grid">
                {brands.map((brand, index) => (
                    <Link key={index} to={`/search/${brand}`} className="brand-link">
                        <BrandButton brandName={brand} />
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default SearchPage;
