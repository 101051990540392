import React, { useEffect, useState } from 'react';
import './AccountInfoPage.css';
import { auth } from '../firebase';
import { useNavigate } from "react-router-dom";
import { updateProfile, updatePassword, reauthenticateWithCredential } from "firebase/auth";

function AccountInfoPage() {
    const [name, setName] = useState("");
    const [displayPassword, setDisplayPassword] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [displaySetPassword, setDisplaySetPassword] = useState(false);
    let navigate = useNavigate();

    const handleSaveChanges = () => {
        // Updating the display name
        updateProfile(auth.currentUser, {
            displayName: name
        }).then(() => {
            // Update successful
        }).catch((error) => {
            // An error occurred
        });

    };

    const handleBackClick = () => {
        navigate("/account");
    };

    useEffect(() => {
        const user = auth.currentUser;
        if (user != null) {
            user.providerData.forEach((profile) => {
                console.log("Sign-in provider: " + profile.providerId);
                console.log("Provider-specific UID: " + profile.uid);
                if (profile.providerId === "password") {
                    setDisplaySetPassword(true);
                }
            });
        }
    }, []); // Empty dependency array ensures this effect runs only once on mount


    return (

        <div className="account-info-container">
            <div className="header">
                <button className="backButton" onClick={handleBackClick}>Back</button>
            </div>
            <h2>Account Info Page</h2>

            <span>Please change the user profile as below: </span>

            <span>Name: </span>
            <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />

            {displaySetPassword ? (<div className="input-container">
                <input
                    type={oldPasswordVisible ? "text" : "password"}
                    placeholder="Old Password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                />
                <button onClick={() => setOldPasswordVisible(!oldPasswordVisible)}>
                    {oldPasswordVisible ? "Hide" : "Show"}
                </button>
            </div>) : (<div />)}

            {displaySetPassword ? (<div className="input-container">
                <input
                    type={newPasswordVisible ? "text" : "password"}
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                />
                <button onClick={() => setNewPasswordVisible(!newPasswordVisible)}>
                    {newPasswordVisible ? "Hide" : "Show"}
                </button>
            </div>) : (<div />)}

            <button onClick={handleSaveChanges} className="save-changes-button">Save Changes</button>
        </div>
    );
}

export default AccountInfoPage;