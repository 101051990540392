import React, { useState } from 'react';
import './DropdownComponent.css';
import { toast } from 'react-toastify';

const DropdownComponent = ({ items, product, brand, handleAddToCart, showDropdown, toggleDropdown }) => {
    //  const [showDropdown, setShowDropdown] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleItemClick = (item) => {
        setSelectedItem(item);
    };

    const addToCart = () => {
        handleAddToCart(product, selectedItem, brand);
    }

    return (
        <div className={`dropdown-container ${showDropdown ? 'show' : ''}`}>
            <div className="dropdown-button" onClick={toggleDropdown}>
                {selectedItem ? `Size: ${selectedItem.size} selected` : 'Select an item'}
            </div>
            <div className="dropdown-content">
                {items
                    .filter((item) => item.qty > 0) // Filter items with qty > 0
                    .map((item) => (
                        <div
                            key={item.itemId}
                            className={`item`}
                            onClick={() => handleItemClick(item)}
                        >
                            <small>Size: {item.size} HKD $ {item.salePrice} 庫存:{item.qty}
                                {item.discount < 100 && ` 折扣: ${item.discount / 10}折`}
                            </small>
                        </div>
                    ))}
            </div>
            {selectedItem && (
                <div className="item-details">
                    <p>Size: {selectedItem.size}</p>
                    <p>Promotion Sale Price: {selectedItem.salePrice}</p>
                </div>
            )}
            <button className="add-to-cart-button" onClick={addToCart}>Add To Cart</button>
        </div>
    );
};

export default DropdownComponent;
