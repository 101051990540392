import React, { useState } from 'react';
import './HinTipsComponent.css';
function HintTips() {
    const [showMessage, setShowMessage] = useState(false);

    const toggleMessage = () => {
        setShowMessage(!showMessage);
    };

    return (
        <div className="hint-tips">
            <button onClick={toggleMessage} className="hint-button">?</button>
            {showMessage && (
                <div className="message-box">
                    <p>輸入優惠碼以使用優惠/折扣</p>
                </div>
            )}
        </div>
    );
}

export default HintTips;
