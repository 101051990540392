import React from 'react';
import './PricePopup.css';

function PricePopup(props) {
    return (
        <div className={`price-popup ${props.show ? 'show' : ''}`}>
            <div className="price-popup-content">
                <span className="close-button" onClick={props.onClose}>
                    &times;
                </span>
                {props.mode === 0 && (
                    <div>
                        <h3>不同碼不同價錢</h3>
                        <p>1. 由於我們的買手團隊會於不同國家及店鋪購買貨品，所以會出現不同尺碼不同價錢的情況</p>
                    </div>
                )}
                {props.mode === 1 && (
                    <div>
                        <h3>購買需知</h3>
                        <p>1. 相片有機會有色差，一切以實物為準</p>
                        <p>2. 落單前請確認貨品編號/尺碼/質料等，付款後一律不接受更換/更改</p>
                        <p>3. 名牌手袋/衣服/鞋唔係藝術品，專門店賣的一樣會偶爾有瑕疵，完美主義者請慎重落單</p>
                        <p>4. 貨品出門，怒不退換</p>
                        <p>5. 若收貨後發現有問題，煩請24小時內提出，逾時不受理，請保留完好包裝以便跟進</p>
                        <p>6. Marvoo 只賣100%正貨，真假問題一概不回</p>
                        <p>7. 如訂購之貨品缺貨，將會全數退款</p>
                        <p>8. 所有成功代購之貨品，運輸需時，恕不接受中途要求退款</p>
                        <p>9. 請所有客人購買前考慮清楚訂貨時間，急單建議到香港專門店購買</p>
                        <p>10. 如有任何爭議，Marvoo Luxury Limited 將保留最終決定權</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default PricePopup;
