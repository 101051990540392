const brandsConst = [
    'Alexander McQueen', //143
    'Celine',
    'Prada',
    'Roger Vivier', //2192
    'Gucci', //88
    'Valentino', //210
    'Burberry', //102
    'Balenciaga', //730
    'Marvoo' //231
];


export default brandsConst;