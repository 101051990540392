import React, { useEffect, useState } from 'react';
import './CartComponent.css';
import { useTranslation } from 'react-i18next';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { onAuthStateChanged } from "firebase/auth"; // Import getAuth
import { auth, getCurrentUser } from '../firebase';
import API_URL from '../config/config';
import ReactModal from 'react-modal';
import { commitOrder } from '../API/api';
import { useNavigate } from "react-router-dom";
import HintTips from './HintTipsComponent';

const CartComponent = ({ cartItems, handleDeleteFromCart, handleClearCart }) => {

    const { t, i18n } = useTranslation();
    const [addresses, setAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(0);
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [processingOrder, setProcessingOrder] = useState(false);
    const [completeCheckout, setCompleteCheckout] = useState(false);
    const [showLoginPromptModal, setShowLoginPromptModal] = useState(false);
    const [promoCode, setPromoCode] = useState("");
    const navigate = useNavigate();

    const getTotal = () => {
        return cartItems.reduce((acc, item) => acc + item.selectedItem.salePrice, 0);
    }

    const handleCheckout = () => {
        let currentUser = getCurrentUser();
        if (currentUser == null) {
            setShowLoginPromptModal(true);
            return;
        }
        setShowConfirmModal(true);
    }

    const handleCompleteCheckout = () => {
        handleClearCart();
        navigate("/");
    }

    async function confirmCheckout() {
        try {
            //Showing spinner
            setProcessingOrder(true);
            const response = await commitOrder("test", cartItems, addresses[selectedAddress], promoCode);
            //Stop spinner
            setCompleteCheckout(true);
        } catch (error) {
            console.error('Error during checkout:', error);
            setProcessingOrder(false);
        }
    }

    const handleSelectAddress = () => {
        console.log("Handle Select address");
        setShowAddressModal(true);
    }

    const handleAddressSelection = (index) => {
        setSelectedAddress(index);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            console.log("onAuthStateChanged user: ", user);
            fetchAddress();
        });
        // Cleanup function to unsubscribe to the listener when the component unmounts
        return () => unsubscribe();
    }, []); // Empty dependency array ensures this effect runs only once on mount


    const fetchAddress = async () => {
        try {
            const response = await fetch(`${API_URL}/profile?uid=${auth.currentUser.uid}`);
            const data = await response.json();
            setAddresses(data.profile[0].addresses);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };


    return (
        <div className="cart-page-container">
            <h2>{t('cartPage.cart')}</h2>
            <ul className="cart-list">
                {cartItems.map((cartItem, index) => (
                    <li key={index} className="cart-item">
                        <img
                            src={cartItem.product.images[0]}
                            alt={cartItem.product.name}
                            className="product-image-cart"
                        />
                        <div className="product-details-button-wrapper">
                            <div className="product-detail-cart">
                                <span className="product-brand-cart">{cartItem.brand}</span>
                                <span className="product-brand-cart">{cartItem.product.name}</span>
                                <span className="product-price-cart">Size: {cartItem.selectedItem.size}</span>
                                <span className="product-price-cart">Qty: 1</span>
                                <span className="product-price-cart">Price: ${cartItem.selectedItem.salePrice}</span>
                            </div>
                            <button className="remove-button" onClick={() => handleDeleteFromCart(cartItem.product, cartItem.selectedItem)}>
                                <i className="fa fa-trash"></i> <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </div>
                    </li>
                ))}
            </ul>
            <div className="cart-address" onClick={() => handleSelectAddress()}>
                <h3>{t('cartPage.selectAddress')}</h3>
                <div className="order-card">
                    <div className="order-info">
                        <div className="order-row">
                            <small className="order-row-first">Address 1:</small>
                            <small>
                                {addresses[selectedAddress] ? addresses[selectedAddress].address1 : ""}
                            </small>
                        </div>
                        <div className="order-row">
                            <small className="order-row-first">Address 2:</small>
                            <small>
                                {addresses[selectedAddress] ? addresses[selectedAddress].address2 : ""}
                            </small>
                        </div>
                        <div className="order-row">
                            <small className="order-row-first">Recipient:</small>
                            <small>
                                {addresses[selectedAddress] ? addresses[selectedAddress].recipient : ""}
                            </small>
                        </div>
                        <div className="order-row">
                            <small className="order-row-first">Contact:</small>
                            <small>
                                {addresses[selectedAddress] ? addresses[selectedAddress].contact : ""}
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div className="promo-code-row">
                <label htmlFor="promoCode">Promo Code: </label>
                <HintTips />
                <input
                    className='cart-page-input'
                    type="text"
                    id="promoCode"
                    placeholder="Enter your promo code"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                />
            </div>
            <div className="cart-summary">
                <div className="cart-total">
                    Total: ${getTotal()}
                </div>
                <button className="checkout-button" onClick={() => handleCheckout()}>
                    Checkout
                </button>
            </div>
            <ReactModal
                isOpen={showAddressModal}
                onRequestClose={() => setShowAddressModal(false)}
                contentLabel="Address Selection Modal"
            >
                <div className="address-modal-container">
                    {/* Add your address selection UI here */}
                    <h3>{t('cartPage.selectAddress')}</h3>
                    {/* List of addresses to choose from */}
                    {getCurrentUser() === null ? (
                        <div className="not-logged-in-container">
                            <p>You need to login to add or select an address.</p>
                            <button onClick={() => navigate("/signup")}>Login</button>
                        </div>
                    ) : addresses.length === 0 ? (
                        <div className="no-address-container">
                            <p>No addresses available. Please add an address to proceed.</p>
                            <button onClick={() => navigate("/addressBook")}>Add Address</button>
                        </div>
                    ) : (
                        <div>
                            {/* List of addresses to choose from */}
                            {addresses.map((address, index) => (
                                <div className={`address-card ${index === selectedAddress ? 'selected-address-card' : ''}`} onClick={() => handleAddressSelection(index)}>
                                    <div className="order-info">
                                        <div className="order-row">
                                            <small className="order-row-first">Address 1:</small>
                                            <small>
                                                {address.address1}
                                            </small>
                                        </div>
                                        <div className="order-row">
                                            <small className="order-row-first">Address 2:</small>
                                            <small>
                                                {address.address2}
                                            </small>
                                        </div>
                                        <div className="order-row">
                                            <small className="order-row-first">Recipient:</small>
                                            <small>
                                                {address.recipient}
                                            </small>
                                        </div>
                                        <div className="order-row">
                                            <small className="order-row-first">Contact:</small>
                                            <small>
                                                {address.contact}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {/* Add a close button to close the modal */}
                            <button className="close-button" onClick={() => setShowAddressModal(false)}>Close</button>
                        </div>
                    )}
                    {/* Add a close button to close the modal */}
                    <button className="close-button" onClick={() => setShowAddressModal(false)}>Close</button>
                </div>
            </ReactModal>
            <ReactModal
                isOpen={showConfirmModal}
                onRequestClose={() => setShowConfirmModal(false)}
                contentLabel="Checkout Confirmation Modal"
            >
                <div className="confirm-modal-container">
                    <h3>Confirm Your Order</h3>

                    {processingOrder ? (
                        <div>
                            <p>Thank you!</p>
                        </div>
                    ) : (
                        <div className="thank-you-message">
                            <p>Are you sure you want to place this order?</p>
                        </div>
                    )}

                    {/* Conditionally show the Confirm button */}
                    {!processingOrder && (
                        <button className="checkout-button" onClick={() => confirmCheckout()}>
                            Confirm
                        </button>
                    )}
                    {!completeCheckout && (
                        <button className="close-button" onClick={() => setShowConfirmModal(false)}>
                            Go Back
                        </button>
                    )}
                    {completeCheckout && (
                        <button className="checkout-button" onClick={() => handleCompleteCheckout()}>
                            OK
                        </button>
                    )}
                </div>
            </ReactModal>
            <ReactModal
                isOpen={showLoginPromptModal}
                onRequestClose={() => setShowLoginPromptModal(false)}
                contentLabel="Login Prompt Modal"
            >
                <div className="login-prompt-modal-container">
                    <h3>You need to login to proceed</h3>
                    <button className="login-button" onClick={() => navigate("/signup")}>Go to Login</button>
                    <button className="close-button" onClick={() => setShowLoginPromptModal(false)}>Go Back</button>
                </div>
            </ReactModal>

        </div>
    );
};

export default CartComponent;
