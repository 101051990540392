import React, { useState, useEffect, useRef } from 'react';
import HomePage from './HomePage/HomePage';
import ShopMenu from './ShopMenu/ShopMenu';
import TopNavBar from './NavBar/TopNavBar';
import BottomNavBar from './NavBar/BottomNavBar';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import ProductDetailPage from './ProductPage/ProductDetailPage';
import SignUp from './Account/Signin';
import Account from './Account/Account';
import AccountInfoPage from './Account/AccountInfoPage';
import OrderPage from './Account/OrderPage';
import OrderDetailPage from './Account/OrderDetail';
import AddressBook from './Account/AddressBook';
import MyListPage from './Account/MyList';
import API_URL from './config/config';
import SearchPage from './SearchPage/SearchPage';
import CartComponent from './Cart/CartComponent';
import AdminOrderPage from './Account/AdminOrderPage';
import CreateSPUPage from './Account/AdminSpuPage';
import AdminSpuList from './Account/AdminSpuListPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./App.css";
import AdminItemSearchPage from './Account/AdminItemSearchPage';
import NoFakePledgeStore from './HomePage/NoFakePledgeStore';
import i18n from './Lang/i18n';
function App() {
  const [activePage, setActivePage] = useState('Gucci');
  const [brandId, setBrandId] = useState(88);
  const [categoryId, setCategoryId] = useState(1597);

  const [cartItems, setCartItems] = useState([]);
  const [cartItemCount, setCartItemCount] = useState(0);
  const [showMenu, setShowMenu] = useState(false);

  const [priceAsc, setPriceAsc] = useState(-1);
  const [priceDesc, setPriceDesc] = useState(-1);

  // Map category names to IDs
  const categoryMap = {
    '夹克/外套': 1576,
    '卫衣/针织衫': 1578,
    '上衣': 1582,
    '靴子/高帮鞋': 1586,
    '高跟鞋': 1594,
    '凉鞋/凉拖': 1595,
    '休闲/运动鞋': 1597,
    '双肩包': 1599,
    '手拿包/迷你包': 1600,
    '单肩包/斜挎包': 1604,
    '钱包': 1605,
    '皮带/腰带': 1609,
    '太阳镜': 1610,
    '帽子': 1613,
    '围巾/丝巾': 1618,
    '手提包': 1620,
    '大衣/羽绒服': 1645,
    '平底鞋/便鞋': 1646,
    '坡跟/松糕鞋': 1648,
    '旅行包': 1649,
    '首饰': 1650,
  };

  const handleCategoryClick = (name) => {
    console.log(name);
    // Use the categoryMap to look up the category ID
    const categoryId = categoryMap[name];
    if (categoryId) {
      setCategoryId(categoryId);
    } else {
      console.warn(`Category "${name}" not found.`);
    }
  };

  useEffect(() => {
    // Get cart items from localStorage on component mount
    const storedCartItems = localStorage.getItem('cartItems');
    if (storedCartItems) {
      var cartItemsObj = JSON.parse(storedCartItems);
      setCartItems(cartItemsObj);
      setCartItemCount(cartItemsObj.length);
    }
  }, []);

  const fetchProductId = async (name) => {
    try {
      const response = await fetch(`${API_URL}/brandlist?englishName=${name}`);
      const data = await response.json();
      const brand = data.list[0];
      setActivePage(brand.englishName);
      setBrandId(brand.brandId);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  const handleMenuClick = (name) => {
    console.log("Menu Click: " + name);
    if (name === "中文" || name === "Chinese") {
      i18n.changeLanguage("zh");
    } else {
      i18n.changeLanguage("en");
    }
    fetchProductId(name);
  };

  const handlePriceFilterClick = (name) => {
    if (name === "updateTime") {
      setPriceAsc(-1);
      setPriceDesc(-1);
    } else if (name === "priceAsc") {
      setPriceAsc(1);
      setPriceDesc(-1);
    } else if (name === "priceDesc") {
      setPriceAsc(-1);
      setPriceDesc(1);
    }
  }

  const handleBrandChange = (brand) => {
    console.log("handleBrandChange: " + brand);
    fetchProductId(brand);
  }

  const handleAddToCart = (product, selectedItem, brand) => {
    if (selectedItem == null) return;
    var cartItem = {
      "product": product,
      "selectedItem": selectedItem,
      "brand": brand
    }
    // Check for duplicate items based on product ID and selected item size
    const isDuplicate = cartItems.some(item =>
      item.product.id === product.id && item.selectedItem.size === selectedItem.size
    );
    if (isDuplicate) {
      toast.warn("This item is already in the cart!");
      return;
    }
    const updatedCartItems = [...cartItems, cartItem];
    setCartItems(updatedCartItems);
    setCartItemCount(updatedCartItems.length);
    localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    toast.info("Add to cart success!");
  }

  const handleDeleteFromCart = (product, selectedItem) => {
    // Filter out the item to be removed based on product ID and selected item size
    const updatedCartItems = cartItems.filter(item =>
      !(item.product.id === product.id && item.selectedItem.size === selectedItem.size)
    );

    setCartItems(updatedCartItems);
    setCartItemCount(updatedCartItems.length);  // If you're keeping track of the count separately
    localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    toast.info("Item removed from the cart!");
  }

  const handleClearCart = () => {
    setCartItems([]);
    setCartItemCount(0);
    localStorage.removeItem('cartItems');
  }

  // Function to toggle the menu
  const toggleMenu = () => {
    console.log("toggleMenu");
    setShowMenu((prevShowMenu) => !prevShowMenu);
  };

  const closeMenu = () => {
    setShowMenu(false);
  }

  const handleMainContainerClick = () => {
    setShowMenu(false);
  }

  return (
    <div>
      <ToastContainer />
      <BrowserRouter>
        <TopNavBar
          handleMenuClick={handleMenuClick}
          cartItemCount={cartItemCount}
          showMenu={showMenu}
          toggleMenu={toggleMenu}
          closeMenu={closeMenu}
        />
        {/* {activePage === 'home' ? <HomePage /> : <ShopMenu />} */}
        <div className="container-fluid" id="main" onClick={() => handleMainContainerClick()}>
          <Routes>
            <Route path="/" element={
              <HomePage
                handleCategoryClick={handleCategoryClick}
                handleBrandChange={handleBrandChange}
                activePage={activePage}
                brandId={brandId}
                categoryId={categoryId}
                priceAsc={priceAsc}
                priceDesc={priceDesc}
                handleMenuClick={handleMenuClick}
                handlePriceFilterClick={handlePriceFilterClick}
              />}
            />
            <Route path="/product/:productId" element={<ProductDetailPage handleAddToCart={handleAddToCart} />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/account" element={<Account />} />
            <Route path="/accountInfo" element={<AccountInfoPage />} />
            <Route path="/order" element={<OrderPage />} />
            <Route path="/order/:orderId" element={<OrderDetailPage />} />
            <Route path="/addressBook" element={<AddressBook />} />
            <Route path="/mylist" element={<MyListPage />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/cart" element={<CartComponent cartItems={cartItems} handleDeleteFromCart={handleDeleteFromCart} handleClearCart={handleClearCart} />} />
            <Route path="/adminPage" element={<AdminOrderPage />} />
            <Route path="/adminOrderIMPage" element={<AdminItemSearchPage />} />
            <Route path="/adminSpuPage" element={<CreateSPUPage />} />
            <Route path="/adminSpuListPage" element={<AdminSpuList />} />
            <Route path="/noFakeStore" element={<NoFakePledgeStore />} />
          </Routes>
        </div>
        <BottomNavBar />
      </BrowserRouter>
    </div>
  );
}

export default App;
