import React, { useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import './AdminSpuPage.css'
import AdminButtonRow from '../NavBar/AdminButtonNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_URL from '../config/config';

function CreateSPUPage() {
    const [name, setName] = useState('');
    const [productId, setProductId] = useState('');
    const [desc, setDesc] = useState('');
    const [images, setImages] = useState([]);
    const [brandId, setBrandId] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [price, setPrice] = useState('');
    const [items, setItems] = useState([{ size: '', salePrice: '' }]);

    const handleImageChange = (e) => {
        // Append new images to the existing images
        setImages([...images, ...Array.from(e.target.files)]);
    };

    const removeImage = (index) => {
        // Remove the image at the specified index
        setImages(images.filter((_, idx) => idx !== index));
    };

    const handleImageUpload = (e) => {
        setImages([...images, ...e.target.files]);
    };

    const addItem = () => {
        setItems([...items, { size: '', salePrice: '' }]);
    };

    const handleItemChange = (e, index) => {
        const updatedItems = items.map((item, idx) =>
            idx === index ? { ...item, [e.target.name]: e.target.value } : item
        );
        setItems(updatedItems);
    };

    const removeItem = (index) => {
        const updatedItems = items.filter((_, idx) => idx !== index);
        setItems(updatedItems);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        // Construct form data to handle file uploads
        const formData = new FormData();
        formData.append('name', name);
        formData.append('productId', productId);
        formData.append('desc', desc);
        formData.append('brandId', brandId);
        formData.append('categoryId', categoryId);
        formData.append('price', price);
        images.forEach(image => formData.append('images', image));
        formData.append('items', JSON.stringify(items));

        try {
            // Adjust the URL according to your API endpoint
            const apiUrl = `${API_URL}/spulist/create-spu`;
            const response = await axios.post(apiUrl, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            console.log(response.data); // Log the response from the server
            toast.info("Create SPU Success");
        } catch (error) {
            console.error('Error creating SPU:', error);
            toast.error("Error creating SPU");
        }
    };

    return (
        <div className="create-spu-page">
            <ToastContainer />
            <AdminButtonRow />
            <h2>Create SPU</h2>
            <form onSubmit={handleSubmit}>
                <label>Name:</label>
                <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />

                <label>Description:</label>
                <textarea value={desc} onChange={(e) => setDesc(e.target.value)} required />

                <label>Price:</label>
                <input type="text" value={price} onChange={(e) => setPrice(e.target.value)} required />

                <label>Images:</label>
                <input type="file" multiple onChange={handleImageChange} />
                <div className="image-preview">
                    {images.map((image, index) => (
                        <div key={index} className="image-container">
                            <img src={URL.createObjectURL(image)} alt="" />
                            <button className="remove-button" type="button" onClick={() => removeImage(index)}>Remove Image</button>
                        </div>
                    ))}
                </div>

                {items.map((item, index) => (
                    <div key={index} className="item">
                        <label>Size:</label>
                        <input type="text" className="item-size-input" name="size" value={item.size} onChange={(e) => handleItemChange(e, index)} required />

                        <label>Sale Price:</label>
                        <input type="number" className="item-size-input" name="salePrice" value={item.salePrice} onChange={(e) => handleItemChange(e, index)} required />

                        {items.length > 1 && <button className="remove-button" type="button" onClick={() => removeItem(index)}>Remove Item</button>}

                        {index < items.length - 1 && <hr />}
                    </div>
                ))}

                <button className="add-item-button" type="button" onClick={addItem}>Add Item</button>
                <button className="create-spu-button" type="submit">Create SPU</button>
            </form>
        </div>
    );
}


export default CreateSPUPage;
