import React, { useEffect } from 'react';
import './NoFakePledgeStore.css'; // Import your CSS file for styling
import myImage from '../assets/noFake.png';

const NoFakePledgeStore = () => {
    useEffect(() => {
        // Scroll to the top of the page when the component is mounted
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="no-fake-image-container">
            <img src={myImage} alt="My Image" className="my-image" />
            <p className='marvoo-slogan-1'>Marvoo 為2023年正版正貨網店計劃成員，
                保証只賣 100% 正版正貨</p>
        </div>
    );
};

export default NoFakePledgeStore;
