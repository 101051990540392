import React, { useState } from 'react';
import axios from 'axios';
import './AdminItemSearchPage.css';
import AdminButtonRow from '../NavBar/AdminButtonNav';

function AdminItemSearchPage() {
    const [designerId, setDesignerId] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [selectedItemId, setSelectedItemId] = useState('');
    const [selectedSalePrice, setSelectedSalePrice] = useState('');
    const [channelOrderNo, setChannelOrderNo] = useState('');
    const [apiResponse, setApiResponse] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSearch = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`http://18.166.69.200:8888/spulist?status=1&designerId=${designerId}`);
            const filteredItems = response.data.list.flatMap(item =>
                item.items.filter(subItem => subItem.qty !== 0 && subItem.status === 1)
            );
            setSearchResults(filteredItems);
        } catch (error) {
            console.error('Error during API call:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAddItem = (itemId, salePrice) => {
        setSelectedItemId(itemId);
        setSelectedSalePrice(salePrice);
    };

    const handleCreateOrder = async () => {
        try {
            setApiResponse("Loading");
            setLoading(true);
            const response = await axios.post('http://18.166.69.200:8888/intra/createIMOrder', {
                itemId: selectedItemId,
                salePrice: selectedSalePrice,
                priceType: 0,
                channelOrderNo: channelOrderNo
            });
            setApiResponse(JSON.stringify(response.data, null, 2));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error during order creation:', error);
            setApiResponse(JSON.stringify(error.response.data, null, 2));
        }
    };

    return (
        <div className="admin-item-search-page">
            <AdminButtonRow />
            <br />
            <input
                type="text"
                value={designerId}
                onChange={(e) => setDesignerId(e.target.value)}
                placeholder="Enter Designer ID"
            />
            <button onClick={handleSearch}>Search</button>

            {loading && <div>Loading...</div>}

            <table>
                <thead>
                    <tr>
                        <th>Item ID</th>
                        <th>Size</th>
                        <th>Sale Price</th>
                        <th>Qty</th>
                        <th>Shipping Address</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {searchResults.map(item => (
                        <tr key={item._id}>
                            <td>{item.itemId}</td>
                            <td>{item.size}</td>
                            <td>{item.salePrice}</td>
                            <td>{item.qty}</td>
                            <td>{item.shippingAddress}</td>
                            <td>
                                <button onClick={() => handleAddItem(item.itemId, item.salePrice)}>Add</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div>
                <input type="text" value={selectedItemId} readOnly placeholder="Selected Item ID" />
                <input type="text" value={selectedSalePrice} readOnly placeholder="Selected Sale Price" />
                <input
                    type="text"
                    value={channelOrderNo}
                    onChange={(e) => setChannelOrderNo(e.target.value)}
                    placeholder="Channel Order No"
                />
                <button onClick={handleCreateOrder}>Create IM Order</button>
            </div>

            <textarea value={apiResponse} readOnly style={{ width: '100%', height: '200px' }} />
        </div>
    );
}

export default AdminItemSearchPage;
