import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import './OrderPage.css';
import { auth } from '../firebase';
import { onAuthStateChanged } from "firebase/auth"; // Import getAuth
import { getMyOrderWithOrderNumber } from '../API/api';

function OrderDetailPage(props) {
    const { orderId } = useParams();
    const [myOrder, setMyOrder] = useState({});
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate("/order");
    };

    useEffect(() => {
        console.log(orderId);
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            fetchMyOrder();
        });
        // Cleanup function to unsubscribe to the listener when the component unmounts
        return () => unsubscribe();
    }, []); // Empty dependency array ensures this effect runs only once on mount

    const fetchMyOrder = async () => {
        try {
            let data = await getMyOrderWithOrderNumber("", orderId);
            console.log(data.outputList);
            setMyOrder(data.outputList[0]);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    }


    // This is just sample data. You should replace this with real data.
    const order =
    {
        orderNumber: '12345',
        orderDate: '2022-01-01',
        price: '$100.00',
        status: 'Shipped',
        itemName: 'Item 1',
        paymentMethod: 'MasterCard 55234******',
        shipment: 'SF Express SF11234',
        paymentAddress1: 'Eric Cheung',
        paymentAddress2: '5C Wan Chai, 4 Kwong Ming St',
        paymentAddress3: 'Hong Kong',
        shipmentAddress1: 'Eric Cheung',
        shipmentAddress2: '5C Wan Chai, 4 Kwong Ming St',
        shipmentAddress3: 'Hong Kong',
        platformFee: "100 HKD",
        total: "300 HKD"
    };

    const items = [
        {
            image: 'https://via.placeholder.com/150',
            description: 'Item 1 Description',
            size: 'Medium',
            price: '$29.99'
        },
        {
            image: 'https://via.placeholder.com/150',
            description: 'Item 2 Description',
            size: 'Large',
            price: '$39.99'
        },
        // Add more items as necessary
    ];

    return (
        <div className="order-page-container">
            <div className="header">
                <button className="backButton" onClick={handleBackClick}>Back</button>
            </div>
            <h2>Order Detail</h2>

            <div className="order-list">
                <div className="order-card">
                    {Object.keys(myOrder).length > 0 && ( // Check if myOrder is not empty

                        <div className="order-info">
                            <div className="order-row"><small>Order Number:</small> <small>{myOrder.orderNumber}</small></div>
                            <div className="order-row"><small>Order Date:</small> <small>{myOrder.orderDate}</small></div>
                            <div className="order-row"><small>Price:</small> <small>{myOrder.price}</small></div>
                            <div className="order-row"><small>Shipment:</small> <small>{myOrder.shipment}</small></div>
                            <div className="order-row"><small>Address Line 1:</small> <small>{myOrder.address.address1}</small></div>
                            <div className="order-row"><small>Address Line 2:</small> <small>{myOrder.address.address2}</small></div>
                            <div className="order-row"><small>Recipient:</small> <small>{myOrder.address.recipient}</small></div>
                            <div className="order-row"><small>Contact:</small> <small>{myOrder.address.contact}</small></div>
                            <div className="order-row"><small>Promo Code:</small> <small>{myOrder.promoCode}</small></div>
                            <div className="order-row"><small>Payment:</small> <small>{myOrder.payment}</small></div>
                            <div className="order-row"><small>Status:</small> <small>{myOrder.status}</small></div>
                            <div className="order-row"><small>Remark:</small> <small>{myOrder.remark}</small></div>
                        </div>

                    )}
                </div>
                <table className="itemsContainer">
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Description</th>
                            <th>Size</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(myOrder).length > 0 && myOrder.items.map((item, index) => (
                            <tr key={index}>
                                <td><img className="order-detail-image" src={item.thumbnail} alt={item.desc} /></td>
                                <td>{item.desc}</td>
                                <td>{item.size}</td>
                                <td>{item.price}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="order-card">
                    <div className="order-row"><small>Total:</small> <small> HKD ${myOrder.price}</small></div>
                </div>
            </div>
        </div>
    );
}

export default OrderDetailPage;